import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import moment from 'moment';
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
const PriceComparisionGrid = () => {
    const [rowData, setRowData] = useState([]);
    const [PCrowData, setPCRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [permissions, setPermissions] = useState([]);
    const [POTab, setPOTab] = useState(false);
    const [pendingTab, setPendingTab] = useState(true);
    const [pcPagination, setPCPagination] = useState();
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('Price Comparison', 'edit');
    // const canCreateItemMaster = checkPermission('Price Comparison', 'create');

    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    useEffect(() => {
        getItems();
        getPC()
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    useEffect(() => {
        if (pageSize) {
            getItems();
            getPC()
        }
    }, [pageSize]);
    const handleEdit = (data) => {
        navigate("/Inventory/PriceComparison/price", { state: data })
    };
    const handleView = (data) => {
        if (data.status === 1) {
            const updatedData = { ...data, doctype: "Comparison" };
            navigate(`/inventory/PriceComparison/View/${updatedData.pcNo}`, { state: updatedData })
        } else {
            const updatedData = { ...data, doctype: "UpdateComparison" };
            navigate(`/inventory/PriceComparison/View/${updatedData.pcNo}`, { state: updatedData })
        }
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('status', 1);
            const res = await apiService.getApiParamater('GET_PR', `?${params.toString()}`);
            const storeData = res?.data?.result?.filter(item => item?.reqType === "Purchase Request");
            const PCres = await apiService.get('GET_PC');
            const Qotres = await apiService.get('GET_QUOTATION');
            setPagination(res.data.paginationData);
            const recordMap = new Map(Qotres.data.result?.map(qot => [qot.requisitionNo, qot]));
            const pcRequisitionNos = new Set(PCres.data.result?.map(pc => pc.requisitionNo));
            const newModifiedData = storeData?.map(item => {
                const matchingQotRecord = recordMap?.get(item?.requisitionNo?.toString());
                return {
                    ...item,
                    comparisonStatus: matchingQotRecord?.status || null,
                    qotRecordId: matchingQotRecord?.quoteNo || null,
                    found: matchingQotRecord?.recordId ? true : false,
                    compare: matchingQotRecord?.status === 1 ? true : false,
                };
            });
            const filteredModifiedData = newModifiedData.filter(item => {
                return !pcRequisitionNos.has(item.requisitionNo);
            });
            setRowData(filteredModifiedData || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const getPC = async (newPageNo = pcPagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_PC', `?${params.toString()}`);
            const data = res?.data?.result || [];
            setPCRowData(data);
            setPCPagination(res.data.paginationData)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        var stillUtc = moment.utc(params.value).toDate();
        var local = moment(stillUtc).local().format("DD-MMM-YYYY");
        // const date = moment(params.value);
        return local;
    };
    const Comparison = [
        {
            headerName: 'Req No', field: 'requisitionNo', filter: true, floatingfilter: true,suppressMovable: true , width: 160,
        },
        {
            headerName: 'Quote No', field: 'quoteNo', filter: true, floatingfilter: true,suppressMovable: true , width: 160,
        },
        {
            headerName: 'PC No', field: 'pcNo', filter: true, floatingfilter: true,suppressMovable: true , width: 140,
        },
        {
            headerName: 'Date', field: 'pcDate',
            floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
            filter: true, floatingfilter: true,suppressMovable: true , width: 150
        },
        { headerName: 'Department', field: 'department', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        { headerName: 'Cost Centre 1', field: 'costCenter1', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        { headerName: 'Cost Centre 2', field: 'costCenter2', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            },suppressMovable: true , width: 180
        },
        { headerName: 'Approval date', field: 'createdDate', filter: true, floatingfilter: true,suppressMovable: true , width: 140 },
        {
            headerName: 'Active', field: 'id', cellRenderer: PrinterBtn,
            cellRendererParams: {
                handleView: handleView,
                canEdit: canEditItemMaster,
                clicked: function (field, isActive) {
                    // updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const columnDefs = [
        { headerName: 'Req No', field: 'requisitionNo', filter: true, floatingfilter: true,suppressMovable: true , width: 180 },
        {
            headerName: 'Quote No', field: 'qotRecordId', filter: true, floatingfilter: true,suppressMovable: true , width: 210,
        },
        {
            headerName: 'Date', field: 'requisitionDate',
            floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
            filter: true, floatingfilter: true,suppressMovable: true , width: 150
        },
        { headerName: 'Department', field: 'location', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        { headerName: 'Cost Centre 1', field: 'costCenter1', filter: true, floatingfilter: true,suppressMovable: true , width: 210 },
        { headerName: 'Cost Centre 2', field: 'costCenter2', filter: true, floatingfilter: true,suppressMovable: true , width: 210 },
        {
            headerName: 'Status', field: 'comparisonStatus', filter: true, floatingfilter: true,suppressMovable: true , width: 150,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'QUotations', filter: true, floatingfilter: true,suppressMovable: true , width: 130,
            cellRenderer: function (params) {
                if (params.data.comparisonStatus === 1) {
                    return (
                        <>
                            {/* {canEditItemMaster && ( */}
                            <button style={{ color: '#1032E7', textDecoration: 'none', border: "none" }} disabled class="btn ">Quotation</button>
                            {/* )} */}
                        </>
                    );
                } else {
                    return (
                        // <>
                        //     {canCreateItemMaster && (
                        <button style={{ color: '#1032E7', textDecoration: 'underline' }} onClick={() => handleComparison(params?.data)} class="btn ">Quotation</button>
                        //     )}
                        // </>
                    );
                }
            }
        }, {
            headerName: 'Active', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEdit: canEditItemMaster,
                canCreateItemMaster: canCreateItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    // updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handleComparison = (data) => {
        navigate("/Inventory/PriceComparison/price", { state: data })
    }
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    <h4 className='col mainInvHead'>Price Comparison</h4>
                    <div className="cost-center-section">
                        <div className="d-flex mt-3 cursor-pointer slide-header"
                            onClick={() => setPendingTab(!pendingTab)}>
                            <div className='h6 pt-1'>Pending For Price Comparison</div>
                        </div>
                        <div className={`slide-content ${pendingTab ? 'open' : 'closed'}`}>
                            <div className={`border-box2 p-2${pendingTab ? 'slide-in' : 'slide-out'}`}>
                                <div className="ag-theme-quartz" style={{ height: 600 }}>
                                    <AgGridReact
                                        getRowStyle={(params) => {
                                            if (params.node.rowIndex % 2 === 0) {
                                                return { background: '#F0EDF5' };
                                            } else {
                                                return { background: '#fff' };
                                            }
                                        }}
                                        rowData={rowData}
                                        enableFilter={true}
                                        floatingFilter={true}
                                        rowSelection={'single'}
                                        columnDefs={columnDefs}
                                        pagination={false}
                                        suppressExcelExport={true}
                                        suppressRowClickSelection={true}
                                        frameworkComponents={{ frameworkComponents }}
                                    />
                                </div>
                                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Price Comparison</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-2${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={PCrowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={Comparison}
                                pagination={false}
                                suppressExcelExport={true}
                                suppressRowClickSelection={true}
                                frameworkComponents={{ frameworkComponents }}
                            />
                        </div>
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pcPagination} getItems={getPC} />
                    </div>
                </div>
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }

    render() {
        const { data } = this.props;
        const { canEdit } = this.props;
        const isDisabled = data.comparisonStatus !== 1;
        const buttonStyle = isDisabled ? { cursor: 'not-allowed' } : {};
        return (
            <div>
                {!isDisabled ?
                    <button
                        onClick={this.btnEditClickedHandler}
                        className="gridbtn p-0"
                        disabled={isDisabled}
                        style={buttonStyle}
                    >
                        Price Comparison
                    </button> :
                    <button
                        className="gridDisab p-0"
                        disabled={isDisabled}
                        style={buttonStyle}
                    >
                        Price Comparison
                    </button>}
            </div>
        );
    }
}
class PrinterBtn extends Component {
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handleView } = this.props;
        handleView(data);
    }

    render() {
        const { canEdit } = this.props;

        return (
            <div>
                {/* {canEdit && ( */}
                <button onClick={this.btnEditClickedHandler} className="gridbtn p-0">Price Comparison</button>
                {/* )} */}
            </div>
        )
    }
}
export default PriceComparisionGrid; 