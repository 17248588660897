import React, { useEffect, useState } from 'react';
import "../singleOrder.css";
import "./item.css";
import img from "../../../../../assets/images/Item-3.png";
import { connect } from "react-redux";
import ApiService from '../../../../../services/apiService';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const SingleOrderItem = ({ val, Currency, orderdata, isCombo, isAddOn }) => {
    const [menu, setMenu] = useState('');
    const [variants, setVariants] = useState([]);
    const [addons, setAddons] = useState([]);
    const [modifiers, setModifiers] = useState([]);
    useEffect(() => {
        if (orderdata && !isCombo) {
            const variantItems = orderdata?.orderItemsMeta?.filter(item => item?.metaType === 4 && item.metaItemIndex === val.itemIndex) || [];
            debugger
            const addonItems = orderdata?.orderItemsMeta?.filter(item => item?.metaType === 2 && item.metaItemIndex === val.itemIndex) || [];
            const modifierItems = orderdata?.orderItemsMeta?.filter(item => item?.metaType === 3 && item.metaItemIndex === val.itemIndex || item?.modifierActive) || [];
            setVariants(variantItems);
            setModifiers(modifierItems);
        }
    }, [orderdata, val, isCombo]);

    useEffect(() => {
        if (orderdata) {
            if (!isCombo) {
                handleMenu();
            }
        }
    }, [orderdata, isCombo]);

    const handleMenu = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Id=${val?.itemId}`;
            let res = await apiService.getApiParamater('GET_MENU', parameters);
            if (res?.data?.statusCode === 200) {
                setMenu(res?.data?.result?.[0]);
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
    };

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        debugger

        if (variants.length > 0) {
            variants.forEach(variant => {
                totalPrice += variant.variantPrice * val?.quantity;
            });
        } else {
            totalPrice += val?.menuItemPrice * val?.quantity;
        }

        modifiers?.forEach(modifier => {

            totalPrice += modifier.metaItemPrice * val?.quantity;
        });

        addons?.forEach(addon => {
            totalPrice += addon.metaItemPrice * val?.quantity;
        });

        return totalPrice;
    };


    const calculateComboPrice = () => {
        return val.comboPrice * val.quantity;
    };
    const calculateAddOnPrice = () => {
        
        return val?.addOnPrice * val?.quantity;

    };

    return (
        <div>
            {/* Display Order Items */}
            {!isCombo && !isAddOn && (
                <div style={val?.voidItem === true ? { border: '2px solid red', borderRadius: '10px', padding: '10px' } : {}} className='row'>
                    <div className='col-lg-2'>
                        <img src={!menu?.imageUrl ? img : IMAGE_URL + menu?.imageUrl} className='pikitm' alt={menu?.name} />
                    </div>
                    <div className='col-lg-8'>
                        <>
                            {val?.voidItem === true && (
                                <div style={{ borderRadius: '7px' }} className='bg-danger text-center justify-content-center m-0'>
                                    <h5 style={{ color: 'white' }} className='p-2'>Voided</h5>
                                </div>
                            )}
                            <div className='itmnme'>{menu?.name}</div>
                            <div className=''>
                                {addons.length > 0 && (
                                    <div className='d-flex'>
                                        <div>Add On</div> <div className="mx-2"> : </div>
                                        {addons.map((addon, key) => (
                                            <div key={key} className='itmKIns'>{addon?.metaItemName} <div className="vr mx-2"></div></div>
                                        ))}
                                    </div>
                                )}
                                {modifiers.length > 0 && (
                                    <div className='d-flex'>
                                        <div>Modifiers</div> <div className="mx-2"> : </div>
                                        {modifiers.map((modifier, key) => (
                                            <div key={key} className='itmKIns'>{modifier?.metaItemName} <div className="vr mx-2"></div></div>
                                        ))}
                                    </div>
                                )}
                                {variants.length > 0 && (
                                    <div className='d-flex'>
                                        <div>Variants</div> <div className="mx-2"> : </div>
                                        {variants.map((variant, key) => (
                                            <div key={key} className='itmKIns'>{variant?.metaItemName} <div className="vr mx-2"></div></div>

                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    </div>
                    <div className='col-lg-2 pt-3'>
                        <div>
                            <div className='itmprc'>{calculateTotalPrice().toFixed(2)} {Currency.currency.name}</div>
                            <div className='itmKIns pt-3'>Qty{" "}: {val?.quantity}</div>
                        </div>
                    </div>
                </div>
            )}

            {/* Display Combos */}
            {isCombo && (
                <div className='combos-section'>
                    <div className='row'>
                        <div className='col-lg-2'>
                            <img src={val.comboImageUrl ? IMAGE_URL + val.comboImageUrl : img} alt={val.comboName} className='pikitm' />
                        </div>
                        <div className='col-lg-8'>
                            <div className='itmnme'>{val.comboName}
                            </div>
                            <div className='mt-3'>
                                {val.comboItems.map((item, key) => (
                                    <div key={key} className=''>
                                        <div className='mt-1'>{item.itemName} ({item.variantName})</div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className='col-lg-2'>
                            <div className='itmprc'>{calculateComboPrice().toFixed(2)} {Currency.currency.name}</div>
                            <div className='itmKIns pt-3'>Qty{" "}: {val.quantity}</div>
                        </div>
                    </div>
                </div>
            )}
            {isAddOn && (
                <div className='combos-section'>
                    <div className='row'>
                        <div className='col-lg-2'>
                            <img src={val.addOnImage ? IMAGE_URL + val.addOnImage : img} alt={val.addOnName} className='pikitm' />
                        </div>
                        <div className='col-lg-8'>
                            <div className='itmnme'>{val.addOnName}
                            </div>
                            <div className='mt-3'>

                            </div>

                        </div>
                        <div className='col-lg-2'>
                            <div className='itmprc'>{calculateAddOnPrice().toFixed(2)} {Currency.currency.name}</div>
                            <div className='itmKIns pt-3'>Qty{" "}: {val.quantity}</div>
                        </div>
                    </div>
                </div>
            )}

            <div className="separator py-3">
                <hr />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SingleOrderItem);
