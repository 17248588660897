import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../services/apiService';
import { ReactComponent as Add } from '../../../Assets/svg/Add.svg';
import { AiOutlinePrinter } from "react-icons/ai"
import { FaRegEye } from "react-icons/fa6"
import moment from 'moment';
import { ReactComponent as Edit } from '../../../Assets/svg/edit.svg'
import { TbBallpenOff } from "react-icons/tb";
import { IoAddSharp } from "react-icons/io5";
import { GrEdit } from "react-icons/gr";
import Pagination from '../../pagination/pagination';
const GRNGrid = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pagination, setPagination] = useState();
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);
    useEffect(() => {
        getItems();
    }, []);
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    let eye = true
                    const updatedData = { ...clickedRowData, eye: eye };
                    navigate(`/inventory/GRN/Update/${clickedRowData.grnNo}`, { state: updatedData })
                } else {
                    navigate(`/inventory/GRN/Update/${clickedRowData.grnNo}`, { state: updatedData })
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    let eye = true
                    const updatedData = { ...selectedRow, eye: eye };
                    navigate(`/inventory/GRN/Update/${selectedRow.grnNo}`, { state: updatedData })
                } else {
                    navigate(`/inventory/GRN/Update/${selectedRow.grnNo}`, { state: updatedData })
                }
            }
        }

        if (selectedRow) {
            navigate(`/inventory/GRN/Update/${selectedRow.grnNo}`, { state: selectedRow })
        }
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_GRN', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            const reversedData = res?.data?.result;
            const grnData = reversedData?.filter(dep => dep.grnNo !== dep?.pOrderNo)
            setRowData(grnData || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/inventory/GRN/${selectedRow?.grnNo}`, { state: selectedRow })
        }
    }
    const handleEye = () => {
        if (selectedRow) {
            let eye = true
            const updatedData = { ...selectedRow, eye: eye };
            navigate("/inventory/GRN/Create", { state: updatedData })
        }
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY") || "";
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'GRN No', field: 'grnNo', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'PO No', field: 'pOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        {
            headerName: 'Date', field: 'modifiedOn', filter: true, floatingfilter: true, suppressMovable: true, width: 200,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Supplier Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 140,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Approval date', field: 'approvalDate', filter: true, floatingfilter: true, suppressMovable: true, width: 200,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        // {
        //     headerName: 'Active', field: 'id', cellRenderer: PrinterBtn,suppressMovable: true , width: 170,
        //     cellRendererParams: {
        //         handleEye: handleEye,
        //         handleEdit: handleEdit,
        //         handlePrint: handlePrint,
        //         clicked: function (field, isActive) {
        //             // updateActiveStatus(field, isActive);
        //         },
        //     },
        // }
    ];
    const onCellClicked = (event) => {
        if (event.colDef.field === 'grnNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const handlenew = () => {
        navigate('/inventory/GRN/Create')
    }
    return (
        <div className='p-4'>

            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='justify-content-between d-flex align-items-center'>
                        <h4 className='mainInvHead'>
                            GRN List
                        </h4>
                        <div className='d-flex justify-content-end gap-3'>
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>View</div>
                            </div>
                            <div
                                onClick={selectedRow && selectedRow.status === 1 ? handleEdit : null}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status !== 1 ? 'disabled' : ''
                                    }`}
                                style={{
                                    cursor: selectedRow && selectedRow.status === 1 ? 'pointer' : 'not-allowed',
                                }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div
                                onClick={handlePrint}
                                className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <AiOutlinePrinter color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Print</div>
                            </div>
                            <div className='col center'>
                                <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                    <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='gridshad  mt-3' style={{ height: "600px" }}>
                        <div className="ag-theme-quartz" style={{ height: "600px" }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                onCellClicked={onCellClicked}
                            />
                        </div>
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                    </div>
                </>
            )}
        </div>
    );
};
class PrinterBtn extends Component {
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnEyeClickedHandler = this.btnEyeClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
        this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
    }

    btnPrintClickedHandler() {
        const { data, handlePrint } = this.props;
        handlePrint(data);
    }
    btnEyeClickedHandler() {
        const { data, handleEye } = this.props;
        handleEye(data);
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }
    render() {
        return (
            <div className='d-flex gap-3'>
                {this.props.data?.status === 1 ?
                    <div><TbBallpenOff size={23} /></div>
                    :
                    <div><Edit onClick={this.btnEditClickedHandler} size={20} /></div>
                }
                <div>
                    <FaRegEye onClick={this.btnEyeClickedHandler} size={25} />
                </div>
                <div>
                    <AiOutlinePrinter onClick={this.btnPrintClickedHandler} size={25} />
                </div>
            </div>
        )
    }
}
export default GRNGrid; 