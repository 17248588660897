import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import ItemGrid from './itemList';
import Authorization from './authorization';
import moment from 'moment';
import MessageGrid from '../purchaseRequisition/messageGrid';
import { FaAngleLeft } from "react-icons/fa6";

const PurchaseOrderView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemsData, setRequisitionData] = useState([]);
    const [suppliers, setSupplier] = useState([]);
    const [payments, setPayments] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [POdata, setitemData] = useState();
    const [usersDetails, setUser] = useState([]);
    const [Supplier, setSupplierData] = useState([]);
    const [SupplierID, setSupplierID] = useState([]);
    const [SupplierContact, setSupplierContact] = useState();
    const [Payment, setPayment] = useState([]);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const apiService = new ApiService();
    const location = useLocation();
    useEffect(() => {
        fetchPayment()
        fetchSuplier()
        getDepartment()
        getCurrency()
        getApprovalUser()
    }, []);
    useEffect(() => {
        const item = location.state;
        const getPR = async () => {
            try {
                const response = await apiService.get('GET_PO');
                const selectedPr = response.data.result?.find(rec => rec.pOrderNo === item.docno)
                setitemData(selectedPr)
                setFormData({
                    PayOrderNo: selectedPr.pOrderNo,
                    Remarks: selectedPr.remarks,
                    DocumentDate: selectedPr.docDate,
                    deliveryDate: selectedPr.deliveryDate,
                    deliveryPoint: selectedPr.deliveryPoint,
                    location: selectedPr.location,
                    contactPerson: selectedPr.contactPerson,
                    requisitionNo: selectedPr.requisitionNo,
                    Department: selectedPr.department,
                    Description: selectedPr.description,
                    costCenter1: selectedPr?.costCenter1,
                    costCenter2: selectedPr?.costCenter2,
                    costCenter3: selectedPr?.costCenter3,
                    costCenter4: selectedPr?.costCenter4,
                });
                const selectedpaymentTerm = payments?.find(payment => payment?.description === selectedPr?.terms);
                if (selectedpaymentTerm) {
                    setPayment({ label: selectedpaymentTerm?.description, value: selectedpaymentTerm })
                }
                const selectedSuplier = suppliers?.find(sup => sup?.supplierName === selectedPr?.vendorName);
                if (selectedSuplier) {
                    setSupplierData({ label: selectedSuplier?.supplierName, value: selectedSuplier })
                    setSupplierID({ label: selectedSuplier?.record_Id, value: selectedSuplier })
                    setSupplierContact(selectedSuplier?.phoneNo1)
                }

                setIsVatExcluded(selectedPr?.costCenter5)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (item) {
            getPR()
        }
    }, [payments, suppliers, location.state]);
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        Remarks: "",
        DocumentDate: "",
        SupplierContact: "",
        deliveryDate: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        requisitionNo: "",
        RequisitionType: "",
        ContactNo: "",
        currencyCode: "",
        QuotationReq: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        exchangeRate: "",
        Description: "",
        BuyerName: "",
        vendorId: "",
        vendorName: "",
        OrderType: "",
        paymentTerm: [],
        SupplierDep: [],
    });
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=PO`;
            const apiService = new ApiService();
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')
    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const handleBackClick = () => {
        window.history.back();
    }
    const orderType = [
        { value: 'Local Purchase', label: 'Local Purchase' },
        { value: 'Import Purchase', label: 'Import Purchase' },
        { value: 'Fixed Assist Purchase', label: 'Fixed Assist Purchase' },
        { value: 'Non Inventory Purchase', label: 'Non Inventory Purchase' },
        { value: 'Hire Purchase', label: 'Hire Purchase' },
        { value: 'Internal Pay Order', label: 'Internal Pay Order' },
        { value: 'Open Pay Order', label: 'Open Pay Order' },
    ];
    let param = `?isActive=true`
    const fetchPayment = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_EPAYMENT');
            const activeData = response?.data?.result.filter(res => res.isActive === true);
            setPayments(activeData);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        setFormData({
            exchangeRate: filteredDenominations?.conversionRate
        })
    }
    const fetchSuplier = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_SUPPLIER');
            const activeData = response?.data?.result.filter(res => res.exp4 === "1");
            setSupplier(activeData);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const getDepartment = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_DEPARTMENT_MASTER');
            const activeData = response?.data?.result.filter(res => res.isActive === true);
            setDepartment(activeData);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const handleVatChange = () => {
        setIsVatExcluded(!isVatExcluded);
    };
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Purchase Order</h5>
                <div className='d-flex gap-3'>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Purchase Order Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group justify-content-between row pb-3">
                            <div className="col">
                                <label htmlFor="InvoiceNO" className="form-label  pt-2 start">Purchase Order No</label>
                                <input type='text'
                                    className="form-Input w-70"
                                    value={formData.PayOrderNo}
                                    name="PayOrderNo"
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="DeliveryDate" className="form-label  pt-2 start">Delivery Date</label>
                                <input
                                    className='form-Input w-70'
                                    type='datetime-local' disabled
                                    value={formData.deliveryDate}
                                    name="deliveryDate"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Currency" className="form-label  pt-2 start">Currency </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0" isDisabled
                                    value={SelectedCurrency}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="InvoiceDate" className="form-label  pt-2 start">Document Date</label>
                                <input
                                    className='form-Input w-70'
                                    type='date' disabled
                                    defaultValue={new Date().toISOString().substr(0, 10)}
                                    name="DocumentDate"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="deliveryPoint" className="form-label  pt-2 start">Delivery Point</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData?.deliveryPoint}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="ExchangeRate" className="form-label  pt-2 start">Exchange Rate </label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.exchangeRate}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="requisitionNo" className="form-label  pt-2 start">Requisition No</label>
                                <input
                                    className="form-Input w-70" name='requisitionNo'
                                    id="requisitionNo" disabled
                                    value={formData.requisitionNo}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="location" className="form-label  pt-2 start">Location</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.location}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Description" className="form-label  pt-2 start">Description</label>
                                <textarea disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.Description}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="RequisitionType" className="form-label  pt-2 start">Requisition Type</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.RequisitionType}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="contactPerson" className="form-label  pt-2 start">Contact Person</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.contactPerson}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Remarks" className="form-label  pt-2 start">Remarks</label>
                                <textarea disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.Remarks}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="QuotationReq" className="form-label  pt-2 start">Quotation Req</label>
                                <input disabled
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    value={formData.QuotationReq}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="ContactNo" className="form-label  pt-2 start">Contact No</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.ContactNo}
                                />
                            </div>
                            <div className="col-4">
                                <label htmlFor="Description" className="form-label  pt-2 start">Pay OrderType</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0" isDisabled
                                    options={orderType}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-4">
                                <label htmlFor="BuyerName" className="form-label  pt-2 start">Buyer Name</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.BuyerName}
                                />
                            </div>
                            <div className='col-1 px-5'></div>
                            <div className='col-lg-2 brderbox center'><a href='' disabled>Attachment</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Supplier Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Vendor ID</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0" isDisabled
                                    value={SupplierID}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Vendor Name</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0" isDisabled
                                    value={Supplier}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Payment terms</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0" isDisabled
                                    value={Payment}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Supplier Dept</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0" isDisabled
                                    value={formData.SupplierDep}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Property" className="form-label  pt-2 start">Supplier Contact</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={SupplierContact}
                                />
                            </div>
                            <div className="col">
                                <div className="form-check my-3">
                                    <input
                                        style={{ width: "26px", height: "26px" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={isVatExcluded} disabled
                                        onChange={handleVatChange}
                                    />
                                    <label className="form-check-label mx-2 mt-1">
                                        Vat Excluded
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="Department" className="form-label  pt-2 start">Property Group</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData?.costCenter1}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Outlet" className="form-label  pt-2 start">Property</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.costCenter2}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Department" className="form-label  pt-2 start">Department</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.costCenter3}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-4">
                                <label htmlFor="Outlets" className="form-label  pt-2 start">Outlets</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.costCenter4}
                                />
                            </div>
                            <div className="col-4">
                                <label htmlFor="CostCentre5" className="form-label  pt-2 start">Cost Centre 5</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    value={formData.CostCentre5}
                                />
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Recommendation
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <ItemGrid onRequisitionData={handleRequisitionData} itemData={POdata} selectedCheck={isVatExcluded} handleVatChange={handleVatChange} />
                )}
                {gridVisibility[1] && (
                    <Authorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={location.state}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {gridVisibility[3] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
            </div>
        </div>

    )
}

export default PurchaseOrderView