import axios from "axios";
import { endPoint } from "../constants/endPoints";
import { openDB } from 'idb'; // IndexedDB helper library

const defaultHeaders = {
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
};

class ApiService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: "http://posapi.qfinity.net",
      headers: defaultHeaders,
    });

    this.axiosInstance.interceptors.request.use(this.requestInterceptor);
    this.axiosInstance.interceptors.response.use(this.responseInterceptor, this.errorInterceptor);

    this.initDB(); // Initialize IndexedDB when the service is constructed

    // Sync data when online
    window.addEventListener('online', this.syncData.bind(this));
  }

  // Initialize IndexedDB
  async initDB() {
    this.db = await openDB('my-app-db', 1, {
      upgrade(db) {
        db.createObjectStore('orders', { keyPath: 'id', autoIncrement: true });
        db.createObjectStore('menus', { keyPath: 'id', autoIncrement: true });
        db.createObjectStore('syncQueue', { keyPath: 'id', autoIncrement: true }); // To queue requests for syncing
      },
    });
  }

  // Request interceptor to add authorization header
  requestInterceptor = (config) => {
    const token = this.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  };

  // Response interceptor
  responseInterceptor = (response) => {
    return response;
  };

  // Error interceptor
  errorInterceptor = async (error) => {
    if (error.response && error.response.status === 401) {
      const token = this.getToken();
      if (token) {
        await this.handleRefreshToken();
        const newToken = this.getToken();
        if (newToken) {
          error.config.headers['Authorization'] = `Bearer ${newToken}`;
          return axios.request(error.config);
        } else {
          return Promise.reject(error);
        }
      } else if (this.isLoginOrSignupRequest(error.config)) {
        return axios.request(error.config);
      } else {
        return Promise.reject(new Error('Unauthorized'));
      }
    } else {
      return Promise.reject(error);
    }
  };

  // Check if the application is online
  isOnline() {
    return navigator.onLine;
  }

  // Offline data management for GET requests
  async handleOfflineGet(path) {
    if (path === 'GET_MENUS') {
      const cachedMenus = await this.db.getAll('menus');
      if (cachedMenus.length > 0) {
        console.log('Returning cached menus:', cachedMenus);
        return { data: { result: cachedMenus } };
      }
    } else if (path === 'GET_ORDERS') {
      const cachedOrders = await this.db.getAll('orders');
      if (cachedOrders.length > 0) {
        console.log('Returning cached orders:', cachedOrders);
        return { data: { result: cachedOrders } };
      }
    }
    // Add more cases as needed
  }

  // Offline data management for POST/PUT requests
  async handleOfflinePostPut(path, data, method) {
    // Queue the request for later synchronization
    await this.db.add('syncQueue', { path, data, method });
    console.log(`Request saved in sync queue: ${method} ${path}`, data);
    
    // Optionally save data locally for immediate use
    if (path === 'CREATE_ORDER') {
      await this.db.put('orders', data);
    } else if (path === 'CREATE_MENU') {
      await this.db.put('menus', data);
    }
  }

  // Sync offline data when online
  async syncData() {
    if (this.isOnline()) {
      const queue = await this.db.getAll('syncQueue');
      for (const request of queue) {
        try {
          if (request.method === 'POST') {
            await this.post(request.path, request.data);
          } else if (request.method === 'PUT') {
            await this.put(request.path, request.data);
          }
          await this.db.delete('syncQueue', request.id); // Remove from queue after successful sync
          console.log('Request synced:', request);
        } catch (error) {
          console.error('Failed to sync request:', error);
        }
      }
    }
  }

  // Standard API methods with online/offline handling
  async get(path) {
    if (!this.isOnline()) {
      // Use cached data when offline
      const offlineResponse = await this.handleOfflineGet(path);
      if (offlineResponse) return offlineResponse;
    }

    // Proceed with the API call if online
    return this.axiosInstance.get(endPoint[path])
      .then(async (response) => {
        // Save fetched data locally when online
        if (this.isOnline() && path === 'GET_MENUS') {
          // Clear existing menus to avoid duplicates
          const tx = this.db.transaction('menus', 'readwrite');
          const store = tx.objectStore('menus');
          await store.clear();

          // Save new menus to the database
          for (const menu of response.data.result) {
            await store.put(menu);
          }
          await tx.done;
        } else if (this.isOnline() && path === 'GET_ORDERS') {
          // Clear existing orders to avoid duplicates
          const tx = this.db.transaction('orders', 'readwrite');
          const store = tx.objectStore('orders');
          await store.clear();

          // Save new orders to the database
          for (const order of response.data.result) {
            await store.put(order);
          }
          await tx.done;
        }
        return response;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error;
      });
  }

  // API call with parameter support, similar to GET but allows URL parameters
  async getApiParamater(path, data) {
    if (!this.isOnline()) {
      // Handle offline scenario if specific cached data is needed
      console.log('Offline: Unable to fetch data with parameters.');
      return; // You can add more logic if you have specific caching for parameterized GET requests
    }

    // Proceed with the API call if online
    return this.axiosInstance.get(endPoint[path] + data)
      .catch((error) => {
        console.error('Error fetching data with parameters:', error);
        throw error;
      });
  }

  async post(path, data) {
    if (!this.isOnline()) {
      // Queue the POST request for later if offline
      await this.handleOfflinePostPut(path, data, 'POST');
      return; // Exit if offline
    }

    // Proceed with the API call if online
    return this.axiosInstance.post(endPoint[path], data)
      .catch((error) => {
        console.error('Error posting data:', error);
        throw error;
      });
  }

  async put(path, data) {
    if (!this.isOnline()) {
      // Queue the PUT request for later if offline
      await this.handleOfflinePostPut(path, data, 'PUT');
      return; // Exit if offline
    }

    // Proceed with the API call if online
    return this.axiosInstance.put(endPoint[path], data)
      .catch((error) => {
        console.error('Error putting data:', error);
        throw error;
      });
  }

  async delete(path, data) {
    if (!this.isOnline()) {
      console.log('Offline mode: DELETE request will not be processed.');
      return; // Exit if offline
    }

    return this.axiosInstance.delete(endPoint[path], { data })
      .catch((error) => {
        console.error('Error deleting data:', error);
        throw error;
      });
  }

  // Token management
  getToken() {
    return localStorage.getItem('Token');
  }

  setToken(token) {
    localStorage.setItem('Token', token);
  }

  getRefreshToken() {
    return localStorage.getItem('RefreshToken');
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem('RefreshToken', refreshToken);
  }

  async handleRefreshToken() {
    try {
      const response = await this.axiosInstance.post(endPoint['REFRESH_TOKEN'], {
        token: this.getToken(),
        refreshToken: this.getRefreshToken(),
      });
      if (response.status === 200) {
        this.setToken(response.data.result.token);
      }
    } catch (error) {
      console.error('Token refresh failed', error);
    }
  }

  // Helper to identify login/signup requests
  isLoginOrSignupRequest(config) {
    const url = config.url;
    return url.includes('USER_LOGIN') || url.includes('USER_SIGNUP');
  }
}

export default ApiService;
