import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { IoAddSharp } from "react-icons/io5";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import Select from 'react-select';
import { FaFileCircleExclamation } from "react-icons/fa6";
import { RiRefreshLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { FaRegEye } from "react-icons/fa6";

const Listing = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    let navigate = useNavigate();
    const [Location, setLocation] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const roleAccess = JSON.parse(localStorage.getItem('RoleAccess'));
    const [selectedRow, setSelectedRow] = useState(null);
    // const selectedRole = roleAccess?.find(acc => acc.description === "Item Master");
    const [formData, setFormData] = useState({
        ItemNo: "",
        email: "",
        ItemDec: "",
        Category: [{ label: 'Select Category', value: 'Select Category' }],
        SubCategory: [{ label: 'Select Sub Category', value: 'Select Sub Category' }],
        StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
        Warehouse: [{ label: 'Select Ware House', value: 'Select Sub Category' }],
    });
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, [pageSize]);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let res = await apiService.get('GET_WAREHOUSE_MASTER')
                setLocation(res?.data?.result)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
    }, []);
    const handleEdit = (clickedRowData) => {
        if (selectedRow || clickedRowData) {
            navigate("/Inventory/Master/Item/Create", { state: selectedRow || clickedRowData });
        }
    };
    const handleEye = () => {
        if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate("/Inventory/Master/Item/Create", { state: updatedData });
        }
    }
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('Item Master', 'edit');
    // const canCreateItemMaster = checkPermission('Item Master', 'create');

    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('ItemNo', formData?.ItemNo || "");
            params.append('ItemDescription', formData?.ItemDec || "");
            params.append('Warehouse', formData?.Warehouse.label || "");
            const res = await apiService.getApiParamater('GET_ITEM', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'itemNo', field: 'itemNo', filter: true, floatingfilter: true, suppressMovable: true, width: 100 },
        { headerName: 'Item Description', field: 'itemDescription', filter: true, floatingfilter: true, suppressMovable: true, width: 300 },
        { headerName: 'Packing UOM', field: 'baseUnit', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Inventory UOM', field: 'unitMeasurement', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Packing Type', field: 'packingType', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Conversion Rate', field: 'convToInvUOM', filter: true, floatingFilter: false },
        { headerName: 'Category', field: 'category', filter: true, floatingFilter: false },
        { headerName: 'Sub Category', field: 'subCategory', filter: true, floatingFilter: false },
        { headerName: 'Supplier', field: 'supplier', filter: true, floatingFilter: false },
        { headerName: 'Purchase Price', field: 'purchasePrice', filter: true, floatingFilter: false },
        {
            headerName: 'Actions', field: 'recordId', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEdit: canEditItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, exp9) {
                    handleToggle(field, exp9);
                },
            }, suppressMovable: true, width: 132
        },
    ];
    const onCellClicked = (event) => {
        if (event.colDef.field === 'itemNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: id,
                exp9: isActive ? "1" : "0"
            };
            const res = await apiService.put(`UPDATE_ITEM`, body);
            if (res?.status === 200) {
                toast.success('Item Updated successfully');
                getItems();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const handlenew = () => {
        navigate('/Inventory/Master/Item/Create');
    };
    const handleToggle = (recordId, newExp9Value) => {
        updateActiveStatus(recordId, newExp9Value === "1");
    };
    const handleClearFilters = () => {
        setFormData({
            ItemNo: "",
            ItemDec: "",
            Warehouse: [{ label: 'Select Ware House', value: 'Select Sub Category' }],
        });
        getItems()
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, Warehouse: selectedOption });
    };
    return (
        <div className='px-4 pt-3'>
            {loading ? (
                <div className='center'>
                    <PropagateLoader color="#6E5199" size={15} />
                </div>
            ) : (
                <>
                    <div className='gridBg mx-1 row' style={{ borderRadius: "16px 16px 16px 16px" }}>
                        <div className='col-lg-8 colmd-9 center gap-3'>
                            <div className='col-4 d-flex justify-content-between'>
                                <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Item No</label>
                                <input
                                    type='text'
                                    className="form-Input w-70"
                                    value={formData.ItemNo}
                                    name="ItemNo"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-4 d-flex justify-content-between'>
                                <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Item Description</label>
                                <input
                                    type='text'
                                    className="form-Input w-70"
                                    value={formData.ItemDec}
                                    name="ItemDec"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-4 d-flex justify-content-between'>
                                <label htmlFor="PropertyGroup" className="form-label  pt-2 start">WareHouse</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 2,
                                        height: 25,
                                        minHeight: 25
                                    })}
                                    className="w-70 p-0"
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    value={formData?.Warehouse}
                                    onChange={(selectedOption) => handlelocChange(selectedOption)}
                                    options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                                />
                            </div>
                        </div>
                        <div className='col D-none'></div>
                        <div className='col-3 '>
                            <div onClick={() => getItems()} className='mainbtnGry flex-end gap-2'>
                                <FaFileCircleExclamation color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Search</div>
                            </div>
                            <div onClick={() => handleClearFilters()} className='mainbtnRed flex-end mt-1 gap-2'>
                                <RiRefreshLine color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Reset</div>
                            </div>
                        </div>
                    </div>
                    <div className='gridBg mt-3'>
                        <div className='justify-content-between d-flex align-items-center pb-2 border-0'>
                            <h5 className='col mainInvHead'>Item Master List</h5>
                            <div className='d-flex gap-1'>
                                <div
                                    onClick={handleEdit}
                                    className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <GrEdit color='#fff' className='center mt-1 mx-2' />
                                    <div className='center'>Edit</div>
                                </div>
                                <div
                                    onClick={handleEye}
                                    className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <FaRegEye color='#fff' className='center mt-1' />
                                    <div className='center'>View</div>
                                </div>
                                {canCreateItemMaster && (
                                    <div onClick={handlenew} className='mainbtn flex-end gap-1'>
                                        <IoAddSharp color='#fff' className='center mt-1 mx-1' size={18} />
                                        <div className='center'>Add</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="gridshad">
                            <div className="ag-theme-quartz" style={{ height: 700 }}>
                                <AgGridReact
                                    getRowStyle={(params) => {
                                        return params.node.rowIndex % 2 === 0
                                            ? { background: '#F0EDF5' }
                                            : { background: '#fff' };
                                    }}
                                    rowData={rowData}
                                    rowSelection={'single'}
                                    columnDefs={columnDefs}
                                    pagination={false}
                                    onSelectionChanged={onSelectionChanged}
                                    onCellClicked={onCellClicked}
                                />
                            </div>
                        </div>
                        <Pagination setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                    </div>
                </>
            )}
        </div>
    );
};


class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newExp9Value = data.exp9 === "1" ? "0" : "1";
        clicked(value, newExp9Value);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }

    render() {
        const { exp9 } = this.props.data;
        const { canEdit } = this.props;
        return (
            <div className='d-flex gap-3'>
                {/* {canEdit && (
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={25} /></div>
                )}*/}
                {/* {canEdit && ( */}
                <div className="form-check form-switch center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={exp9 === "1"}
                        onChange={this.btnClickedHandler}
                    />
                </div>
                {/* )}  */}
            </div>
        );
    }
}


export default Listing;
