import React, { useEffect } from "react";
import chart1 from "../../Assets/images/chart1.png";
import chart2 from "../../Assets/images/chart2.png";
import chart3 from "../../Assets/images/3.png";
import chart4 from "../../Assets/images/4.png";
import chart5 from "../../Assets/images/5.png";
import chart6 from "../../Assets/images/6.png";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiServiceInv from "../../services/apiService";
import { saveLogin, setUserRole } from "../../../Redux/actions";
import userManagement from "../../../POS/services/userManagement";
import { invokeLogin } from "../../../POS/services/signalRService";
import { getName } from "../../../Redux/selecters";

const DashBoard = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth);
    const userName = useSelector(getName);
    useEffect(() => {
        if (!userName) {
            handleLogin();
        }
    }, [userName]);
    useEffect(() => {
        if (!localStorage.getItem("Token")) {
            handleLogin();
        }
    }, []);

    const handleLogin = async () => {
        const apiServiceInv = new ApiServiceInv();
        let body = {
            email: "demo@gmail.com",
            password: "12345678",
        };
        const response = await apiServiceInv.post("USER_LOGIN", body);
        if (response && response.status === 200) {
            dispatch(saveLogin(response?.data))
            const userRole = userManagement.getUserRole(response?.data?.data?.accessToken);
            dispatch(setUserRole(userRole))
            localStorage.setItem('Token', response?.data?.data?.accessToken)
            const userId = userManagement.getUserId(response?.data?.data?.accessToken);
            const InvUserId = userManagement?.getUserId2(response?.data?.data?.accessToken);
            if (userId) {
                invokeLogin(userId);
            }
        }
    };

    return (
        <div className="p-4">
            <div className="row">
                <div className="col-6">
                    <div className="headtxt pb-2">Sale Overview</div>
                    <div><img src={chart1} className="chartimg" /></div>
                </div>
                <div className="col-6">
                    <div className="headtxt pb-2">Purchase Overview</div>
                    <div><img src={chart2} className="chartimg" /></div>
                </div>
            </div>
            <div className="row pt-3 d-flex justify-content-between">
                <div className="col-3"><img src={chart3} className="chartimg" /></div>
                <div className="col-3"><img src={chart4} className="chartimg" /></div>
                <div className="col-3"><img src={chart5} className="chartimg" /></div>
            </div>
            <div className="row pt-3">
                <div className="col-12"><img src={chart6} className="chartimg" /></div>
            </div>
        </div>
    );
};

export default DashBoard;
