import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ItemlistGrid from '../issueItems/itemsGrid';
import IssueAuthorization from '../issueItems/issueAuthorization';
import IssueMessageGrid from '../issueItems/IssueMessage';
import DirectItems from './itemGrid';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";

const CreateIssueDirect = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService2 = new ApiService2()
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [Location, setLocation] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [Userdata, setUserdata] = useState();
    const [loader, setLoader] = useState(false);
    const [itemNo, setItemNo] = useState();
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const itemData = location.state;
    const userName = useSelector(getName);
    const [POTab, setPOTab] = useState(true);

    const todayDate = new Date().toISOString().substr(0, 10);
    const [formData, setFormData] = useState({
        recordId: "",
        requisitionNo: "",
        itemType: "",
        description: "",
        remarks: "",
        requestor: "",
        requisitionDate: currentDatetimeUTC,
        date: todayDate,
        currencyCode: "",
        exchangeRate: "",
        department: [],
        costCenter1: [],
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        fromLocation: [],
        toLocation: [],
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
    });

    useEffect(() => {
        getCurrency()
        getUser()
        getLocation()
        getDepartment()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        setFormData({
            recordId: item?.recordId,
            requisitionDate: item?.requisitionDate || currentDatetimeUTC,
            deliveryDate: item?.deliveryDate ? item.deliveryDate.substr(0, 10) : todayDate,
            requisitionNo: item?.requisitionNo,
            itemType: item?.type,
            quatotionNo: item?.contractNo,
            remarks: item?.remarks,
            requestor: item?.requestor,
            currencyCode: item?.currencyCode,
            costCenter2: item?.costCenter2,
            costCenter3: item?.costCenter3,
            costCenter4: item?.costCenter4,
            deliveryPoint: item?.deliveryPoint,
            contactPerson: item?.contactPerson,
            contatNo: item?.contatNo,
            taskCode: item?.projectCode,
            location: item?.location,
        });
        const selectedDepartment = department?.find(dep => dep.description === item?.department)
        if (selectedDepartment) {
            setFormData(prevState => ({
                ...prevState,
                department: { label: selectedDepartment.description, value: selectedDepartment }
            }));
        }
        const selectedLocation = Location?.find(dep => dep.location === item?.costCenter1)
        if (selectedLocation) {
            setFormData(prevState => ({
                ...prevState,
                costCenter1: { label: selectedLocation.location, value: selectedLocation }
            }));
        }
    }, [location?.state, department, Location]);
    const getApprovalUser = async () => {
        try {
            if (itemData) {
                setItemNo(itemData.requisitionNo);
            } else {
                let docParam = `?TypeOfDoc=STI&DocId=Issue`
                let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
                setItemNo(DocId?.data.result[0].docId);
            }
            const res = await apiService2.get('GET_USERS');
            const selecteduser = res?.data?.result?.find(dep => dep.name === userName)
            setUserdata(selecteduser)
            let param = `?Type=${"issue"}&Company=${selecteduser.company}&Department=IT`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER_LIST', param);
            setUser(response.data.result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        // setFormData({
        //     exchangeRate: filteredDenominations?.conversionRate
        // })
        setFormData({ ...formData, exchangeRate: filteredDenominations?.conversionRate });
    }
    const getLocation = async () => {
        let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
        setLocation(res?.data?.result)
    }
    const getDepartment = async () => {
        let res = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
        setDepartment(res?.data?.result)
    }
    const getUser = async () => {
        try {
            const token = localStorage.getItem('Token');
            let userId = userManagement.getUserId(token)
            let Id = `?id=${userId}`
            const apiService2 = new ApiService2()
            let res = await apiService2.getApiParamater('GET_USERS', Id)
            setUserdata(res?.data?.result)
        } catch (error) {
            console.log(error)
        }

    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const staticOptions = [];
    const totalAmount = requisitionData.reduce((sum, row) => sum + (row?.qty * row.unitPrice || 0), 0);

    const CreatePR = async () => {
        setLoader(true)
        if (!formData.fromLocation) {
            toast.warning("Enter from location");
            setLoader(false)
            return;
        }
        if (requisitionData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        if (!formData.toLocation) {
            toast.warning("Enter To location");
            setLoader(false)
            return;
        }
        for (const item of requisitionData) {
            if (!item.qty) {
                toast.warning(`Please enter ${item.itemDescription} quantity`);
                setLoader(false);
                return;
            }
        }
        try {
            const issueDetailDtos = requisitionData?.map(item => ({
                srno: parseInt(item.srno),
                itemCode: item?.itemNo,
                itemDescription: item?.itemDescription,
                uom: item?.uom,
                qty: parseInt(item?.qty),
                specification: item?.specification,
                convValue: parseFloat(item?.baseQty),
                unitPrice: parseFloat(item?.unitPrice),
                amount: parseFloat(item?.amount),
                baseAmount: parseFloat(item?.amount),
                issueNo: itemNo,
                rsrno: parseInt(item.srno),
                itemDetailId: 0,
                received: 0,
                stockSrNo: 0,
                cgsAccountCode: item?.cgsAcc,
                cgsAccountName: item?.cgsAccName,
                prodDate: moment(formData.date).format(),
                expDate: moment(item.expDate).format(),
            }));
            debugger
            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                issueNo: itemNo,
                issueDate: moment(formData.date).format(),
                description: formData.description,
                // department: formData.department.label,
                department: "IT",
                issuedBy: userName,
                warehouse: formData.fromLocation.label,
                issueRefNo: formData.refNo,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                amount: totalAmount,
                baseAmount: totalAmount,
                status: 0,
                approvalDate: currentDatetimeUTC,
                remarks: formData.remarks,
                costCenter1: "",
                costCenter2: "",
                costCenter3: "",
                costCenter4: "",
                divisionCode: formData.toLocation.label,
                divisionName: formData.toLocation.label,
                issueType: "GENERAL",
                issueDetailDtos: issueDetailDtos,
                modifiedBy: userName,
            };
            const response = await apiService.post("CREATE_ITEM_ISSUE", userData);
            if (response?.data?.statusCode === 201) {
                const docNumber = response?.data?.result?.issueNo
                const userData2 = {
                    docType: "issue",
                    docId: docNumber ? docNumber.toString() : itemNo,
                    department: "IT",
                    company: Userdata?.company || "Qfinity",
                };
                const docBody = {
                    docId: "Issue",
                    typeOfDoc: "STI",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${itemNo} Created successfully`);
                setLoader(false)
                setFormData({
                    itemType: "",
                    quatotionNo: "",
                    remarks: "",
                    requestor: "",
                    requisitionDate: "",
                    deliveryDate: "",
                    currencyCode: "",
                    department: "",
                    costCenter1: "",
                    costCenter2: "",
                    costCenter3: "",
                    costCenter4: "",
                    deliveryPoint: "",
                    location: "",
                    contactPerson: "",
                    contatNo: "",
                    taskCode: "",
                });
                navigate("/inventory/Issue/Direct")
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const UpdatePR = async () => {

    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const handleDepToChange = (selectedOption) => {
        setFormData({ ...formData, department: selectedOption });
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    };
    const handlelocFromChange = (selectedOption) => {
        setFormData({ ...formData, fromLocation: selectedOption });
    };
    const handlelocToChange = (selectedOption) => {
        setFormData({ ...formData, toLocation: selectedOption });
    };
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Issue Inventory Direct</h5>
                <div className='d-flex gap-3'>
                    {itemData?.eye === true ? " " :
                        <>
                            {location.state ? (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={UpdatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Update</div>
                                    </button>
                                }
                                </>
                            ) : (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={CreatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                                    </button>
                                }
                                </>
                            )}</>
                    }
                    {itemData?.eye === true ? " " : <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>}
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Issue Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="recordId" className="form-label  pt-2 start">Issue Note No</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemNo}
                                    name="itemNo"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Department</label>
                                {itemData?.eye === true ?
                                    <input disabled
                                        className="form-Input w-70"
                                        value={formData.department}
                                    /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} value={formData.department}
                                        options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                        onChange={(selectedOption) => handleDepToChange(selectedOption)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property Group</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Date </label>
                                {itemData?.eye === true ?
                                    <input
                                        className='form-Input w-70'
                                        type='date' disabled
                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        type='date'
                                        value={formData.date}
                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                        name="date"
                                        onChange={handleInputChange}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="description" className="form-label  pt-2 start">Description</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.location} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        name="description"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Property</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled value={formData.costCenter1} /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={formData?.costCenter1}
                                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                                        options={Location?.map((loc) => ({ value: loc, label: loc?.location }))}
                                    />}
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Currency</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled value={SelectedCurrency} /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        isSearchable={false}
                                        value={SelectedCurrency}
                                        onChange={(e) => handlecurChange(e)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Issued By</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={{ label: userName, value: userName }}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />}
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Cost Centre 3</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="exchangeRate" className="form-label  pt-2 start">Exchange Rate</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.exchangeRate} className="form-Input w-70" /> :
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        id="exchangeRate"
                                        value={formData.exchangeRate}
                                        onChange={handleInputChange}
                                        name="exchangeRate"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="contactPerson" className="form-label  pt-2 start">Ref No</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.contactPerson} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="contactPerson"
                                        value={formData.contactPerson}
                                        onChange={handleInputChange}
                                        name="contactPerson"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Outlets</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">From Location </label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={formData.fromLocation}
                                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        onChange={(selectedOption) => handlelocFromChange(selectedOption)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start"> To Location</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={formData.toLocation}
                                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        onChange={(selectedOption) => handlelocToChange(selectedOption)}
                                    />}
                            </div>
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="remarks" className="form-label  pt-2 start">Remarks</label>
                                {itemData?.eye === true ?
                                    <input disabled
                                        className="form-Input w-70"
                                        value={formData.remarks}
                                    /> :
                                    <input
                                        name="remarks"
                                        type='text'
                                        className="form-Input w-70"
                                        id="remarks"
                                        value={formData.remarks}
                                        onChange={handleInputChange}
                                    />}
                            </div>

                        </div>
                        <div className="form-group row ">
                            <div className='col-1 px-5 mx-3'></div>
                            <div className=' col-lg-2 brderbox p-2 center'><a href='' disabled>Attachment</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Requisition
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <DirectItems onRequisitionData={handleRequisitionData} itemData={itemData} formData={formData} />
                )}
                {gridVisibility[1] && (
                    <IssueAuthorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <IssueMessageGrid handleMessage={handleMessage} />
                    </div>
                )}

            </div>
        </div>
    )
}

export default CreateIssueDirect