import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AuthorizationGrid from '../../purchaseRequisition/authorization';
import MessageGrid from '../../purchaseRequisition/messageGrid';
import ApiService from '../../../services/apiService';
import ItemGridGRN from './itemGrid';
import { toast } from 'react-toastify';
import AuthorizationGrn from '../authorization';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaAngleLeft } from "react-icons/fa6";
import moment from 'moment';

const GrnView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [POData, setPOData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [WareHouse, setWareHouse] = useState([]);
    const [PurchaseOrder, setPurchaseOrder] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const location = useLocation();
    const itemData = location.state;
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        fetchData()
        getApprovalUser()
        getCurrency()
    }, [])
    useEffect(() => {
        const item = location.state;
        const getGrn = async () => {
            try {
                const response = await apiService.get('GET_GRN');
                const selectedGrn = response.data.result?.find(rec => rec.grnNo === item.docno)
                const selectedOption = PurchaseOrder?.find(po => po?.pOrderNo === selectedGrn?.pOrderNo);
                const selectedWareHouse = WareHouse?.find(po => po?.description === selectedGrn?.warehouse);
                setSelectedPO({ label: selectedGrn?.pOrderNo, value: selectedOption })
                setSelectedSupplier({ label: selectedOption?.vendorName, value: selectedOption })
                setPOData(selectedGrn?.grnDetails)
                const formattedPODate = selectedOption?.docDate ? formatDateToDatetimeLocal(selectedOption?.docDate) : getCurrentLocalDatetime();
                const formattedInvDate = selectedGrn?.invoiceDate ? formatDateToDatetimeLocal(selectedGrn?.invoiceDate) : getCurrentLocalDatetime();
                const formattedDelivDate = selectedGrn?.delivery_Date ? formatDateToDatetimeLocal(selectedGrn?.delivery_Date) : getCurrentLocalDatetime();
                setFormData({
                    GRNNO: item.docno,
                    remarks: selectedGrn?.remarks,
                    InvoiceNo: selectedGrn?.invoiceNo,
                    InvoiceDate: formattedInvDate,
                    deliveryDate: formattedDelivDate,
                    Department: selectedGrn?.department,
                    PurchaseOrderDate: formattedPODate,
                    location: { label: selectedWareHouse?.description, value: selectedWareHouse }
                });
                debugger
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getGrn()
    }, [PurchaseOrder, location.state, WareHouse]);
    const fetchData = async () => {
        const department = await apiService.get("GET_DEPARTMENT_MASTER");
        const WareHouse = await apiService.get("GET_WAREHOUSE_MASTER");
        setWareHouse(WareHouse?.data?.result);
        const PurchaseOrder = await apiService.get("GET_PO");
        const approvedPO = PurchaseOrder.data.result.filter(data => data.status === 1);
        setPurchaseOrder(approvedPO);
    }
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=GRN`;
            const apiService = new ApiService();
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const [formData, setFormData] = useState({
        GRNNO: "",
        deliveryNote: "",
        remarks: "",
        InvoiceNo: "",
        InvoiceDate: "",
        deliveryDate: "",
        PropertyGroup: "",
        TaxInvoice: "",
        Property: "",
        Department: "",
        Outlet: "",
        Currency: "",
        exchangeRate: "",
        deliveryPoint: "",
        PurchaseOrderDate: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        email: '',
        subcatname: "",
        location: ''
    });
    const getCurrency = async () => {
        let param = `?isActive=true`
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const staticOptions = [];
    const handleBackClick = () => {
        window.history.back();
    }
    return (
        <div className='p-5'>
            <div className='d-flex justify-content-between my-2'>
                <div className='h4'>Goods Receipt Note</div>
                <div onClick={handleBackClick} className='mainbtn  flex-end  gap-3'>
                    <FaAngleLeft size={25} className='center' />
                    <div>Back</div>
                </div>
            </div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group gap-3 justify-content-between row pb-3">
                    <div className="col">
                        <label htmlFor="GRNNO" className="form-label">GRN No</label>
                        <input
                            className="form-control"
                            value={formData.GRNNO}
                            disabled
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="catcode" className="form-label">Delivery Date </label>
                        <input className='form-control' type='datetime-local'
                            name="deliveryDate"
                            disabled={itemData?.eye === true ? true : false}
                            value={moment(formData.deliveryDate).format('DD-MMM-YYYY')}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Location" className="form-label">Location</label>
                        <Select
                            value={formData.location}
                            isDisabled={itemData?.eye === true ? true : false}
                            options={WareHouse?.map((sup) => ({ value: sup, label: sup?.description }))}
                        />
                    </div>
                </div>
                <div className="form-group gap-3 justify-content-between row pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Purchase Order No *</label>
                        <Select
                            value={SelectedPO}
                            isDisabled={itemData?.eye === true ? true : false}
                            options={PurchaseOrder?.map((po) => ({ value: po, label: `PO-24-0${po?.recordId}` }))}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="deliveryNote" className="form-label">Delivery Note No</label>
                        <input
                            type="text"
                            className="form-control"
                            disabled={itemData?.eye === true ? true : false}
                            id="deliveryNote"
                            value={formData.deliveryNote}
                            name="deliveryNote"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="TaxInvoice" className="form-label">Tax Invoice No ( KRA )</label>
                        <input
                            type="number" onWheel={(e) => e.target.blur()}
                            className="form-control"
                            disabled={itemData?.eye === true ? true : false}
                            id="TaxInvoice"
                            value={formData.TaxInvoice}
                            name="TaxInvoice"
                        />
                    </div>
                </div>
                <div className="form-group gap-3 justify-content-between row pb-3">
                    <div className="col">
                        <label htmlFor="PurchaseOrderDate" className="form-label">Purchase Order Date</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            disabled={itemData?.eye === true ? true : false}
                            value={moment(formData.PurchaseOrderDate).format('DD-MMM-YYYY')}
                            id="PurchaseOrderDate"
                            name="PurchaseOrderDate"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="InvoiceNo" className="form-label">Invoice No</label>
                        <input
                            type="number" onWheel={(e) => e.target.blur()}
                            className="form-control"
                            id="InvoiceNo"
                            disabled={itemData?.eye === true ? true : false}
                            value={formData.InvoiceNo}
                            name="InvoiceNo"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Delivery Type</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            options={staticOptions}
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Supplier</label>
                        <Select isDisabled={itemData?.eye === true ? true : false} value={SelectedSupplier} />
                    </div>
                    <div className="col">
                        <label htmlFor="InvoiceDate" className="form-label">Invoice Date</label>
                        <input
                            type="datetime-local" className="form-control"
                            id="InvoiceDate"
                            value={moment(formData.InvoiceDate).format('DD-MMM-YYYY')}
                            disabled={itemData?.eye === true ? true : false} name="InvoiceDate"
                        />
                    </div>
                    <div className="col d-flex gap-3">
                        <div className="col">
                            <label htmlFor="Currency" className="form-label">Currency</label>
                            <Select
                                isSearchable={false} isDisabled={itemData?.eye === true ? true : false}
                                value={SelectedCurrency} className="Currency-dropdown mbl-dropdown"
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="EXRate" className="form-label">EX Rate</label>
                            <input
                                type="number" className="form-control" disabled
                                onWheel={(e) => e.target.blur()} id="exchangeRate"
                                value={formData.exchangeRate} name="exchangeRate"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='h4'>Cost Center</div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="PropertyGroup" className="form-label">Property Group</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.PropertyGroup}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Property" className="form-label">Property</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.Property}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Department" className="form-label">Department</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.Department}
                        />
                    </div>
                </div>
                <div className="form-group row  pb-3">
                    <div className="col-4">
                        <label htmlFor="Outlet" className="form-label">Outlet</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.Outlet}
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="remarks" className="form-label">Remarks</label>
                        <input
                            name="remarks" type='text'
                            className="form-control" disabled={itemData?.eye === true ? true : false}
                            id="remarks" value={formData.remarks}
                        />
                    </div>
                    <div className='col-1 px-5 mx-3'></div>
                    <div className='col-lg-2 brderbox center'>
                        <a href=''>Attachment</a>
                    </div>
                </div>
            </div>
            <div className="topnav2 maon-BG ">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Additional Charges
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <ItemGridGRN onRequisitionData={handleRequisitionData} itemData={POData} grnData={itemData} />
                )}
                {gridVisibility[1] && (
                    <AuthorizationGrn handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={POData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {gridVisibility[3] && (
                    <ItemGridGRN onRequisitionData={handleRequisitionData} />
                )}
            </div>
        </div>
    )
}

export default GrnView