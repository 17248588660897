import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import "../purchaseOrder/print.css"
import { FiPrinter } from "react-icons/fi";
import { PiDownloadThin } from "react-icons/pi";
import moment from 'moment';
// import Logo from "../../Assets/images/Qfinitylogos.png"
import Logo from "../../Assets/images/Pro_Logo.jpg"
import ApiService from '../../services/apiService';
import { toWords } from 'number-to-words';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const InvoicePrint = ({ }) => {
    const location = useLocation();
    const CompareData = location.state;
    const [Items, setItems] = useState([]);
    const [suppliers, setSupplier] = useState();
    const currentDatetimeUTC = new Date().toISOString();
    const totalAmount = Items?.reduce((sum, row) => sum + (row.amount || 0), 0);
    const totalVatAmount = Items?.reduce((sum, row) => sum + (row.vatAmount || 0), 0);
    useEffect(() => {
        fetchSupplierData()
        const fetchINVData = async () => {
            try {
                const apiService = new ApiService()
                let param = `?Id=${CompareData?.rcNo}`
                const response = await apiService.getApiParamater('GET_INVOICE', param);
                setItems(response?.data.result[0]?.invoiceRegDetails)
            } catch (error) {
                console.error("Error fetching Supplier data:", error);
            }
        };
        fetchINVData()

    }, [location?.state]);
    const fetchSupplierData = async () => {
        try {
            const apiService = new ApiService()
            let param = `?SupplierName=${CompareData?.vendorName}`
            const response = await apiService.getApiParamater('GET_SUPPLIER', param);
            setSupplier(response?.data?.result[0]);
        } catch (error) {
            console.error("Error fetching Supplier data:", error);
        }
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handlePrintClick = async () => {
        const input = document.getElementById('mainboxdsn');
        if (input) {
            const canvas = await html2canvas(input, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF("landscape");
            pdf.addImage(imgData, 'PNG', 10, 10, 275, 190);
            pdf.save(`Invoice-${CompareData?.invoiceNo}.pdf`);
        } else {
            console.error("Element not found: mainboxdsn");
        }
    };
    return (
        <div className='container p-lg-5'>
            <div className='d-flex justify-content-between py-5'>
                <div className='' onClick={handleBackClick} ><button className='prntbtn'> <MdKeyboardDoubleArrowLeft size={20} /> Back</button></div>
                <div className=''><button className='prntbtn'><IoMdMail size={20} /> Send To Mail</button></div>
                <div className=''><button className='prntbtn'> <FiPrinter size={20} /> Print</button></div>
                <div className=''><button className='downbtn' onClick={handlePrintClick}> <PiDownloadThin size={20} /></button></div>
            </div>
            <div id="mainboxdsn" className='mainboxdsn'>
                <div className='printhead px-5'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <img src={Logo} className="printlogo" alt="Logo" style={{height:"30px"}}/>
                        </div>
                        <div className='mainhead'>
                            <u>Purchase Invoice</u>
                        </div>
                        <div className='headtx'>
                            {/* <div className='py-1'>Order No: {CompareData?.rcNo}</div> */}
                            <div className='pt-1'>Date: {moment(CompareData?.docDate).format('ll')}</div>
                        </div>
                    </div>
                </div>
                <div className='printboxIn p-4 d-flex gap-3'>
                    <div className='printsupbox w-50'>
                        <div className='d-flex justify-content-between'>
                            <div>Invoice No</div>
                            <div>{CompareData?.invoiceNo}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Invoice Date</div>
                            <div>{moment(CompareData?.invoiceDate).format('ll')}</div>
                        </div>
                        {/* <div className='d-flex justify-content-between'>
                            <div>Supplier Invoice no</div>
                            <div>{CompareData?.invoiceNo || "---"}</div>
                        </div> */}
                        <div className='d-flex justify-content-between'>
                            <div>Supplier Invoice Date </div>
                            <div>{moment(CompareData?.docDate).format('ll')}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>PO Order No </div>
                            <div>{CompareData?.pOrderNo}</div>
                        </div>
                    </div>
                    <div className='printsupbox w-50'>
                        <div className='d-flex justify-content-between'>
                            <div>Supplier Code</div>
                            <div>{CompareData?.vendorId}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Supplier</div>
                            <div>{CompareData?.vendorName}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Currency</div>
                            <div>{CompareData?.currencyCode}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Terms</div>
                            <div>{CompareData?.terms}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Status</div>
                            <div>{CompareData?.status === 1 ?
                                <div>Approved</div> : <>
                                    {CompareData?.status === 0 ?
                                        <div>Pending</div> : <>
                                            {CompareData?.status === 2 ?
                                                <div>Hold</div> :
                                                <>{CompareData?.status === 3 ?
                                                    <div>Rejected</div> : <div>Pending</div>
                                                }</>
                                            }
                                        </>
                                    }</>
                            }</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Tax Inv No </div>
                            <div>{suppliers?.phoneNo1}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>{CompareData?.status === 1 ?
                                <div>Delivery Date</div>
                                :
                                <div>Expected Delivery Date</div>
                            }</div>
                            <div>{moment(CompareData?.deliveryDate).format('ll')}</div>
                        </div>
                    </div>

                </div>
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style={{ backgroundColor: '#5B93FF', color: '#fff' }}>
                            <tr>
                                <th>Sr no</th>
                                <th>Item No</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>UOM</th>
                                <th>Unit Price</th>
                                <th>Amount</th>
                                <th>Discount</th>
                                <th>VAT %</th>
                                <th>VAT Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Items?.map((row, index) => (
                                <tr key={index}>
                                    <td className='m-0 p-0'><input style={{ width: "100px" }} className='p-2' type="text" value={index + 1} disabled /></td>
                                    <td className='m-0 p-0'>
                                        <td className='m-0 p-0'><input style={{ width: "110px" }} className='p-2' disabled value={row.itemCode} /></td>
                                    </td>
                                    <td className='m-0 p-0'>
                                        <td className='m-0 p-0'><input style={{ width: "140px" }} className='p-2' disabled value={row.itemDescription} /></td>
                                    </td>
                                    <td className='m-0 p-0'><input style={{ width: "80px" }} className='p-2' disabled value={row.qty} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "150px" }} className='p-2' disabled value={row.uom} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "120px" }} className='p-2' disabled value={row.unitPrice?.toFixed(2) || 0} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "110px" }} className='p-2' disabled value={(row.amount)?.toFixed(2) || 0} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "110px" }} className='p-2' type="text" value={row.stock} disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} type="text" value={row.vatPerc} disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type="text" value={row.vatAmount?.toFixed(2)} disabled /></td>
                                </tr>
                            ))}
                            <tr>
                                <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Total"} /></td>
                                <td className='m-0 p-0'><input className='p-2' style={{ width: "110px" }} disabled value={totalAmount?.toFixed(2)} /></td>
                                <td className='m-0 p-0'><input className='p-2' style={{ width: "110px" }} disabled value={"---"} /></td>
                                <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} disabled value={"---"} /></td>
                                <td className='m-0 p-0'><input className='p-2 w-100' disabled value={totalVatAmount?.toFixed(2)} /></td>
                            </tr>
                            <tr>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Amount In Words"} /></td>
                                <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={toWords(totalAmount)} /></td>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={totalAmount?.toFixed(2)} /></td>
                            </tr>
                            <tr>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Payment Terms"} /></td>
                                <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={CompareData?.terms} /></td>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"---"} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='p-5 row'>
                        <div className=' row d-flex justify-content-between'>
                            <div className='col-2'>
                                <div className='itmKIns center'>Admin</div>
                                <hr className='' />
                                <div className='itmKIns center'>Prepared By</div>
                            </div>
                            <div className='col-2'>
                                <div className='itmKIns center'>Admin</div>
                                <hr className='' />
                                <div className='itmKIns center'>Approved By</div>
                            </div>
                        </div>
                        <div className='center'>
                            <div className='col-3'>
                                <div className='itmKIns center'>{moment(currentDatetimeUTC).format('DD-MMM-YYYY')}</div>
                                <hr className='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoicePrint;
