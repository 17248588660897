import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';

const UpdateItemGridGRN = ({ onRequisitionData, itemData, grnData, selectedPOData }) => {

    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        vatDes: '',
        baseQty: '',
        baseAmount: '',
        amount: '',
        discount: '',
        vat: '',
        convValue: '',
        vatAmount: '',
        expDate: null,
        balQty: "",
        inventoryAccountCode: "",
        inventoryAccountName: "",
    }]);
    const [items, setItems] = useState()
    useEffect(() => {
        const getItems = async () => {
            try {
                const apiService = new ApiService();
                const res = await apiService.get('GET_ITEM');
                setItems(res.data.result)
                const response = await apiService.get('GET_GRN');
                const selectedGrn = response.data.result?.find(rec => rec.grnNo === grnData?.grnNo)
                let param = `?POrderNo=${grnData?.pOrderNo || selectedPOData?.pOrderNo || grnData?.pOrderNo}`
                const PO = await apiService.getApiParamater("GET_GRN_PO", param);
                const formattedRows = itemData?.map((detail, index) => {

                    const selectedGrnItem = selectedGrn?.grnDetails?.find(rec => rec.itemDescription === detail.itemDescription)
                    const selectedItem = PO.data.result?.find(rec => rec.itemDescription === detail.itemDescription)
                    const selecteditem = res.data.result?.find(rec => rec.itemNo === detail.itemCode)
                    if (!selecteditem) {
                        console.error(`Selected item with description ${detail.itemDescription} not found`);
                        return null;
                    }
                    debugger
                    return {
                        requisitionNo: detail.requisitionNo,
                        srno: index + 1,
                        ItemNo: detail.itemCode,
                        qty: selectedGrnItem.qty,
                        itemDescription: detail.itemDescription,
                        uom: detail.uom,
                        baseQty: selecteditem.convToInvUOM * selectedGrnItem.qty,
                        baseAmount: detail.unitPrice,
                        vat: selectedGrnItem.vatPerc,
                        vatDes: selectedGrnItem.vatDesc,
                        convValue: selecteditem.convToInvUOM,
                        vatAmount: selectedGrnItem.vatAmount,
                        specification: detail ? detail.specification : '',
                        amount: grnData?.eye === true ? detail.amount : detail.amount,
                        balQty: grnData?.eye === true ? selectedGrnItem?.grnQty || selectedGrnItem?.balQty : detail?.balQty,
                        expDate: selectedGrnItem?.expDate,
                        inventoryAccountCode: selecteditem.acCode,
                        inventoryAccountName: selecteditem.acName,
                    };
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        if (itemData) {
            getItems();
        }
    }, [itemData]);
    const handleQtyChange = (index, value) => {
        const newQty = parseInt(value) || '';
        const balQty = rows[index].balQty;
        const unitPrice = rows[index].baseAmount;
        const vatPerc = rows[index].vat;
        const vatInclusive = rows[index].vatInclusive;
        const UOM = rows[index].baseQty;
        const item = rows[index].itemDescription;
        const vatDes = rows[index].vatDes;
        // if (newQty > balQty) {
        // toast.warning('Enter a quantity less than or equal to the balance quantity.');
        // } else {
        const amountBeforeVat = newQty * unitPrice;
        const vatAmount = (amountBeforeVat * vatPerc) / 100;
        let totalAmount;

        if (selectedPOData?.vatIncluded === "Excluded") {
            totalAmount = amountBeforeVat + vatAmount;
        } else {
            totalAmount = amountBeforeVat;
        }
        const selecteditem = items?.find(rec => rec.itemDescription === item)
        setRows(prevRows => {
            const newRows = [...prevRows];
            newRows[index].qty = newQty;
            newRows[index].baseQty = newQty * UOM || selecteditem.convToInvUOM;
            newRows[index].amount = totalAmount;
            newRows[index].vatDes = vatDes;
            newRows[index].vatAmount = vatAmount;
            return newRows;
        });
        // }
    };
    const handleDateChange = (index, expDate) => {
        const updatedRows = [...rows];
        updatedRows[index].expDate = expDate;
        const updatedSelectedRows = rows.map((row, i) =>
            i === index ? { ...row, expDate: expDate } : row
        );
        setRows(updatedSelectedRows);
        onRequisitionData(updatedSelectedRows);
    };
    return (
        <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
            <table>
                <thead className='dynoTable'>
                    <tr>
                        <th>Sr no</th>
                        <th>Item No</th>
                        <th>Description</th>
                        <th>Item Specs</th>
                        <th>Qty</th>
                        <th>Packing UOM</th>
                        <th>Inventory Qty</th>
                        <th>Bal Qty</th>
                        <th>Free Qty</th>
                        <th>Unit Price</th>
                        <th>Discount</th>
                        <th>VAT Desc</th>
                        <th>VAT %</th>
                        <th>VAT Amount</th>
                        <th>Amount</th>
                        <th>Exp Date</th>
                    </tr>
                </thead>
                <tbody>
                    {rows?.map((row, index) => (
                        <tr key={index}>
                            <td className='m-0 p-0'><input className='p-2' type="text" value={index + 1} disabled style={{ width: "70px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "120px" }} /></td>
                            <td className='m-0 p-0'>
                                {grnData?.eye === true ?
                                    <input
                                        className='p-2'
                                        type="number" onWheel={(e) => e.target.blur()}
                                        disabled style={{ width: "70px" }}
                                        value={row.qty}
                                    />
                                    :
                                    <input
                                        className='p-2' onWheel={(e) => e.target.blur()}
                                        type="number" style={{ width: "70px" }}
                                        value={row.qty}
                                        max={row.balQty}
                                        onChange={(e) => handleQtyChange(index, e.target.value)}
                                    />
                                }
                            </td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.baseQty || 0} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.balQty || 0} style={{ width: "80px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.freeQty || 0} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.baseAmount || 0} style={{ width: "120px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' type="text" value={row.discount} disabled style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' type="text" value={row.vatDes} disabled style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' type="text" value={row.vat + "%" || 0 + "%"} disabled style={{ width: "80px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' type="text" value={row.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.amount || 0} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' type='date' value={row?.expDate ? row.expDate.split('T')[0] : ''} disabled={grnData?.eye === true ? true : false}
                                onChange={(e) => handleDateChange(index, e.target.value)} style={{ width: "130px" }} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UpdateItemGridGRN;
