import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MessageGrid from '../purchaseRequisition/messageGrid';
import ItemGridGRN from '../Goods&Receipt/grnList/itemGrid';
import { toast } from 'react-toastify';
import AuthorizationGrn from '../Goods&Receipt/authorization';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import DirectPurchaseGrid from './directPurchaseGrid';
import ApiService2 from '../../../POS/services/apiService';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';


const CreateDirectPurchase = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const [POData, setPOData] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [Department, setDepartment] = useState([]);
    const [WareHouse, setWareHouse] = useState([]);
    const [PurchaseOrder, setPurchaseOrder] = useState([]);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const [supplierCheck, setSupplierCheck] = useState("Supplier");
    const [Userdata, setUserdata] = useState();
    const [currency, setCurrency] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const userName = useSelector(getName);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [OtherTab, setOtherTab] = useState(false);
    const [cost1, setCost1] = useState([]);
    const [cost2, setCost2] = useState([]);
    const [cost3, setCost3] = useState([]);
    const [cost4, setCost4] = useState([]);
    const [loader, setLoader] = useState(false);

    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const navigate = useNavigate();

    useEffect(() => {
        fetchData()
        getApprovalUser()
        getCurrency()

    }, [])
    const handleVatChange = () => {
        setIsVatExcluded(!isVatExcluded);
    };
    const handleSuppChange = (val) => {
        setSupplierCheck(val);
    };
    let param = `?isActive=true`
    const fetchData = async () => {
        let docParam = `?TypeOfDoc=GRN&DocId=GRN`
        const docRes = await apiService.getApiParamater("GET_DOC_ID", docParam);
        setFormData({ ...formData, GRNNO: docRes?.data.result[0].docId });
        setPOData(docRes?.data.result[0])
        const Supplier = await apiService.get("GET_SUPPLIER");
        setSupplier(Supplier?.data?.result);
        const department = await apiService.get("GET_DEPARTMENT_MASTER");
        setDepartment(department?.data?.result);
        const WareHouse = await apiService.get("GET_WAREHOUSE_MASTER");
        setWareHouse(WareHouse?.data?.result);
        const PurchaseOrder = await apiService.get("GET_PO");

        let cost1 = await apiService.get('GET_COST_ONE')
        let cost2 = await apiService.get('GET_COST_TWO')
        let cost3 = await apiService.get('GET_COST_THREE')
        let cost4 = await apiService.get('GET_COST_FOUR')
        setCost1(cost1?.data?.result)
        setCost2(cost2?.data?.result)
        setCost3(cost3?.data?.result)
        setCost4(cost4?.data?.result)
        const approvedPO = PurchaseOrder.data.result?.filter(data => data.status === 1);
        setPurchaseOrder(approvedPO);
    }
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        // setFormData({ ...formData, exchangeRate: filteredDenominations?.conversionRate})
    }
    const getApprovalUser = async () => {
        try {
            const apiService2 = new ApiService2()
            const apiService = new ApiService();
            // const res = await apiService2.get('GET_USERS');
            // const selecteduser = res?.data?.result?.find(dep => dep.name === userName)
            // setUserdata(selecteduser)
            // let param = `?Type=${"GRN"}&Company=${selecteduser.company}&Department=IT`;
            // const response = await apiService.getApiParamater('GET_APPROVAL_USER_LIST', param);
            // setUser(response.data.result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const [formData, setFormData] = useState({
        GRNNO: "",
        deliveryNote: "",
        remarks: "",
        InvoiceNo: "",
        InvoiceDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        PropertyGroup: "",
        TaxInvoice: "",
        Property: "",
        Department: "",
        Outlet: "",
        Currency: "",
        exchangeRate: SelectedCurrency?.value?.conversionRate,
        deliveryPoint: "",
        PurchaseOrderDate: getCurrentLocalDatetime(),
        contactPerson: "",
        contatNo: "",
        itemCode: "",
        itemDescription: '',
        subcatname: "",
        location: '',
        vendorName: [],
        vendorId: [],
        addCharges: ''
    });
    const staticOptions = [];
    const handleDepartmentChange = (selectedOption) => {
        setFormData({ ...formData, Department: selectedOption?.description });
    }
    const handleLocationChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const CreateGRN = async () => {
        setLoader(true)
        if (!formData.location.label) {
            toast.warning("Enter location");
            setLoader(false)
            return;
        }
        // if (!formData?.vendorName?.value?.supplierName) {
        //     toast.warning("Select Supplier");
        //     return;
        // }
        if (ItemData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        for (const item of ItemData) {
            if (!item.qty) {
                toast.warning(`Please enter ${item.itemDescription} quantity`);
                setLoader(false)
                return;
            } else if (!item.baseAmount) {
                toast.warning(`Please enter ${item.itemDescription} Unit Price`);
                setLoader(false)
                return;
            }
        }
        try {
            const totalAmount = ItemData.reduce((sum, row) => sum + (row.amount || 0), 0);
            const vatAmount = ItemData.reduce((sum, row) => sum + (row.vatAmount || 0), 0);
            const currentDatetimeUTC = new Date().toISOString();
            const GRNDetailsData = ItemData.map((item, index) => {
                const grossAmount = parseFloat(item.amount) - parseFloat(item.vatAmount || 0);
                return {
                    requisitionNo: item?.requisitionNo,
                    srno: index + 1,
                    itemCode: item?.ItemNo,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: parseInt(item.qty),
                    baseUOM: item?.uom,
                    baseQty: parseInt(item.baseQty),
                    specification: item.specification,
                    convValue: parseFloat(item?.baseQty),
                    unitPrice: parseFloat(item.baseAmount),
                    amount: parseFloat(item.amount),
                    baseAmount: parseFloat(item.amount),
                    convToInvUOM: parseInt(item.baseQty),
                    importedQty: 0,
                    prodDate: currentDatetimeUTC,
                    expDate: item?.expDate || currentDatetimeUTC,
                    isActive: true,
                    returnQty: 0,
                    invoicedQty: 0,
                    extraQty: 0,
                    grossAmount: grossAmount,
                    grossBaseAmount: grossAmount,
                    addCharges: parseFloat(formData.addCharges),
                    vatInclusive: isVatExcluded,
                    vatCode: 1,
                    vatDesc: item.vatDes,
                    vatAmount: item.vatAmount,
                    vatUnitPrice: item.vatAmount / item.qty,
                    vatPerc: item.vat,
                    vatBaseAmount: item.vatAmount,
                    inventoryAccountCode: item?.inventoryAccountCode,
                    inventoryAccountName: item?.inventoryAccountName,
                }
            });
            const userData = {
                grnNo: formData.GRNNO,
                pOrderNo: formData.GRNNO,
                delivery_Date: formData.deliveryDate,
                status: 0,
                invoiceDate: formData.InvoiceDate,
                remarks: formData?.remarks,
                modifiedOn: currentDatetimeUTC,
                modifiedBy: userName,
                vendorId: formData.vendorId?.label.toString(),
                vendorName: formData.vendorName?.label,
                terms: formData.vendorName.value.paymentTerm,
                department: formData?.Department,
                costCenter1: formData.costCenter1?.label,
                costCenter2: formData.costCenter2?.label,
                costCenter3: formData.costCenter3?.label,
                costCenter4: formData.costCenter4?.label,
                isActive: true,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                grnDetailsDtos: GRNDetailsData,
                warehouse: formData?.location.label,
                transport_Mode: "Direct",
                transporter: formData?.vendorName.label,
                invoiceRef: formData.deliveryNote,
                amount: parseFloat(totalAmount),
                baseAmount: parseFloat(totalAmount),
                vatInclusive: isVatExcluded,
                vatAmount: vatAmount,
                grossAmount: parseFloat(totalAmount),
                grossBaseAmount: parseFloat(totalAmount),
            };
            debugger
            const response = await apiService.post("CREATE_GRN", userData);

            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "GRN",
                    docId: formData.GRNNO,
                    department: "IT",
                    company: Userdata?.company || "Qfinity",
                };
                const docBody = {
                    docId: "GRN",
                    typeOfDoc: "GRN",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${formData.GRNNO} Created successfully`);
                setLoader(false)
                navigate("/inventory/DirectPurchase")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const handleSupplierChange = (selectedOption) => {
        setFormData({
            ...formData,
            vendorName: { label: selectedOption.value.supplierName, value: selectedOption },
            vendorId: { label: selectedOption.value.record_Id, value: selectedOption },
        });
    }
    const handleBackClick = () => {
        window.history.back();
    };
    const handleCost1 = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    }
    const handleCost2 = (selectedOption) => {
        setFormData({ ...formData, costCenter2: selectedOption });
    }
    const handleCost3 = (selectedOption) => {
        setFormData({ ...formData, costCenter3: selectedOption });
    }
    const handleCost4 = (selectedOption) => {
        setFormData({ ...formData, costCenter4: selectedOption });
    }
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Direct Purchase</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' type="submit" onClick={CreateGRN}><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    }
                    <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Purchase Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="GRNNO" className="form-label  pt-2 start">DP GRN No</label>
                                <input
                                    className="form-Input w-70"
                                    value={formData.GRNNO}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">GRN Date</label>
                                <input className='form-Input w-70' type='datetime-local' min={currentDatetimeUTC}
                                    name="deliveryDate"
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col center'>
                                <button className='mainbtn ' >Advance Search</button>
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceNo" className="form-label  pt-2 start">Invoice No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="InvoiceNo"
                                    value={formData.InvoiceNo}
                                    onChange={handleInputChange}
                                    name="InvoiceNo"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceDate" className="form-label  pt-2 start">Invoice Date</label>
                                <input
                                    type='datetime-local' min={currentDatetimeUTC}
                                    className="form-Input w-70"
                                    id="InvoiceDate"
                                    value={formData.InvoiceDate}
                                    onChange={handleInputChange}
                                    name="InvoiceDate"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="itemCode" className="form-label  pt-2 start">Item No</label>
                                <input type='text'
                                    className="form-Input w-70" name='itemCode'
                                    value={formData.itemCode}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="TaxInvoice" className="form-label  pt-2 start">Tax Invoice No ( KRA )</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="TaxInvoice"
                                    value={formData.TaxInvoice}
                                    onChange={handleInputChange}
                                    name="TaxInvoice"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="deliveryNote" className="form-label  pt-2 start">Delivery Note No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="deliveryNote"
                                    value={formData.deliveryNote}
                                    onChange={handleInputChange}
                                    name="deliveryNote"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Item Description</label>
                                <input className='form-Input w-70' type='text'
                                    name="itemDescription"
                                    value={formData.itemDescription}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Delivery Type</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    options={staticOptions}
                                    onChange={(selectedOption) => handleInputChange('PropertyGroup', selectedOption)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Location" className="form-label  pt-2 start">Location</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    defaultValue={formData.location}
                                    onChange={handleLocationChange}
                                    options={WareHouse?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="col">
                                <div className="d-flex p-1 brderbox gap-2 justify-content-around " style={{ background: "#d9d9d95e" }}>
                                    <div className="form-check gap-3 center">
                                        <input style={{ width: "20px", height: "20px" }}
                                            onClick={() => handleSuppChange("Supplier")} checked={supplierCheck === "Supplier" ? true : false}
                                            className="form-check-input" type="radio" name="flexRadioDefault" id={supplierCheck} />
                                        <label className="form-check-label" for="Supplier">Supplier</label>
                                    </div>
                                    <div className="form-check gap-3 center" >
                                        <input className="form-check-input" style={{ width: "20px", height: "20px" }}
                                            type="radio" name="flexRadioDefault" id={supplierCheck} checked={supplierCheck === "CashPurchase" ? true : false}
                                            onClick={() => handleSuppChange("CashPurchase")}
                                        />
                                        <label className="form-check-label" for="CashPurchase">Cash Purchase</label>
                                    </div>
                                    <div className="form-check gap-3 center" >
                                        <input className="form-check-input" style={{ width: "20px", height: "20px" }}
                                            type="radio" name="flexRadioDefault" id={supplierCheck} checked={supplierCheck === "sample" ? true : false}
                                            onClick={() => handleSuppChange("sample")}
                                        />
                                        <label className="form-check-label" for="sample">sample</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="addCharges" className="form-label  pt-2 start">Add Charges</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="addCharges"
                                    value={formData.addCharges}
                                    onChange={handleInputChange}
                                    name="addCharges"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Supplier Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Supplier</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.vendorName}
                                    onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    options={supplier?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Supplier ID</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.vendorId}
                                    onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    options={supplier?.map((sup) => ({ value: sup, label: sup?.record_Id }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Currency" className="form-label  pt-2 start">Currency </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isSearchable={false}
                                    value={SelectedCurrency}
                                    onChange={(e) => handlecurChange(e)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="ExchangeRate" className="form-label  pt-2 start">Exchange Rate </label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    id="exchangeRate"
                                    value={SelectedCurrency?.value?.conversionRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Property Group</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter1}
                                    onChange={handleCost1}
                                    options={cost1?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Property" className="form-label  pt-2 start">Property</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" value={formData.costCenter2}
                                    onChange={handleCost2}
                                    options={cost2?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Department" className="form-label  pt-2 start">Department</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" value={formData.costCenter3}
                                    onChange={handleCost3}
                                    options={cost3?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Department" className="form-label  pt-2 start">Outlets</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" value={formData.costCenter4}
                                    onChange={handleCost4}
                                    options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setOtherTab(!OtherTab)}>
                    <div className="h6 pt-1">Other Details</div>
                </div>
                <div className={`slide-content ${OtherTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className='d-flex justify-content-between '>
                            <div className="d-flex justify-content-between col-md-4">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Remarks</label>
                                <input
                                    name="remarks"
                                    type='text'
                                    className="form-Input w-70"
                                    id="subcatname"
                                    value={formData.remarks}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-md-2 center brderbox'><a>Attachment</a></div>
                            <div className="col-md-3 d-flex gap-2 justify-content-around">
                                <div className="form-check">
                                    <input
                                        style={{ width: "26px", height: "26px" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={isVatExcluded}
                                        onChange={handleVatChange}
                                    />
                                    <label className="form-check-label mx-2 mt-1">
                                        Vat Excluded
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-2 mb-3">
                {gridVisibility[0] && (
                    <DirectPurchaseGrid onRequisitionData={handleRequisitionData} itemData={POData} selectedCheck={isVatExcluded} />
                )}
                {gridVisibility[1] && (
                    <AuthorizationGrn handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={POData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {gridVisibility[3] && (
                    <ItemGridGRN onRequisitionData={handleRequisitionData} />
                )}
            </div>
        </div >
    )
}

export default CreateDirectPurchase