import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import moment from 'moment';
import CompanyGridMaster from './grid';
import FileUpload from '../imageUpload/fileUpload';
import { GetCountries, GetState, GetCity, GetLanguages, } from "react-country-state-city";
import ApiServicePOS from '../../../../src/POS/services/apiService';
import { hasPermission } from "../../services/roleService"
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { PropagateLoader } from 'react-spinners';
const CompanyMaster = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [countriesList, setCountries] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState();
    const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/;
    useEffect(() => {
        GetCountries().then((result) => {
            setCountries(result);
        });
    }, []);
    useEffect(() => {
        GetState(selectedCountry?.value?.id).then((result) => {
            setStateList(result);
        });
    }, [selectedCountry]);
    useEffect(() => {
        GetCity(selectedCountry?.value?.id, selectedState?.value?.id).then((result) => {
            setCityList(result);
        });
    }, [selectedState]);
    useEffect(() => {
        fetchCategoryData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchCategoryData();
        }
    }, [pageSize]);
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Company Master', 'edit');
    // const canCreateMaster = checkPermission('Company Master', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;
    const [formData, setFormData] = useState({
        recordId: "",
        companyId: "",
        name: "",
        phoneNumber: "",
        pObox: "",
        web: '',
        fax: '',
        email: '',
        trn: '',
        address: "",
        images: [],
        imageUrl: "",
    })
    const handleEdit = () => {
        if (selectedRow) {
            setFormData({
                companyId: selectedRow?.record_id || "",
                recordId: selectedRow?.companyId,
                name: selectedRow?.companyName,
                phoneNumber: selectedRow?.companyTel,
                pObox: selectedRow?.companyPO,
                web: selectedRow?.companyWeb,
                fax: selectedRow?.companyFax,
                email: selectedRow?.companyEmail,
                trn: selectedRow?.trn,
                address: selectedRow?.companyAdd1,
                imageUrl: selectedRow?.companyLogo,
            });
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'phoneNumber') && value.length > 16) {
            return;
        }
        if ((name === "fax" || name === 'phoneNumber' || name === 'recordId') && value < 0) {
            return;
        }
        setFormData({ ...formData, [name]: value });
    }
    const fetchCategoryData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_COMPANY', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            const packingData = response?.data;
            setRowData(packingData);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching packing data:", error);
            setLoading(false)
        }
    }
    const UploadImage = async () => {

        if (!formData?.images && !formData?.imageUrl) {
            toast.error("Upload Image");
        } else if (formData?.images) {
            try {

                const apiServicePOS = new ApiServicePOS();
                const formdata = new FormData();
                for (const image of formData?.images) {
                    if (image.file) {
                        formdata.append('Images', image?.file);
                    }
                }
                const response = await apiServicePOS.postMultiPart("UPLOAD_IMG", formdata);

                if (response?.data?.statusCode === 201) {
                    setFormData({ ...formData, imageUrl: response?.data?.result });
                    handleSubmit(response?.data?.result)
                }
                else toast.error(response?.data?.msg)
            } catch (error) {
                toast.error("Something went wrong with the image. Please try again!");
            }
        }
    }
    const handleSubmit = async (imgurl) => {
        if (!formData.name) {
            if (!formData.name) { toast.warning("Enter Company Name") }
            return;
        }
        // if (formData.web && !urlRegex.test(formData.web)) {
        //     toast.error("Please enter a valid URL.");
        //     return;
        // }

        if (imgurl) {
            try {
                const apiService = new ApiService()
                let data = {
                    companyId: formData?.recordId,
                    companyName: formData?.name,
                    companyPO: formData?.pObox,
                    companyAdd1: formData?.address,
                    companyCity: formData?.city?.label,
                    companyCountry: formData?.country?.label,
                    companyTel: formData?.phoneNumber,
                    companyFax: formData?.fax,
                    companyEmail: formData?.email,
                    companyWeb: formData?.web,
                    companyLogo: imgurl,
                    companyRemarks: formData?.remarks,
                    trn: formData.trn,
                    isActive: true,
                }
                const response = await apiService.post('CREATE_COMPANY', data);
                if (response?.data?.statusCode === 201) {
                    toast.success(response?.data?.message)
                    fetchCategoryData();
                    setFormData({
                        recordId: "",
                        name: "",
                        phoneNumber: "",
                        pObox: "",
                        web: '',
                        fax: '',
                        email: '',
                        trn: '',
                        images: [],
                        imageUrl: "",
                    });
                } else if (response?.data?.statusCode === 208) {
                    toast?.warning(response?.data?.message)
                    fetchCategoryData();
                }
            } catch (error) {
                toast.error("Please try Again!")
            }
        } else {
            toast.warning('Upload Image');
        }
    }
    const handleUpdateImg = async () => {
        try {

            if (!formData?.images && !formData?.imageUrl) {
                toast.error("Upload Image");
            } else if (formData?.images) {
                try {
                    const apiServicePOS = new ApiServicePOS();
                    const formdata = new FormData();
                    for (const image of formData?.images) {
                        if (image.file) {
                            formdata.append('Images', image?.file);
                        }
                    }
                    const response = await apiServicePOS.postMultiPart("UPLOAD_IMG", formdata);
                    if (response?.data?.statusCode === 201) {
                        setFormData({ ...formData, imageUrl: response?.data?.result });
                        updateFormData(formData?.imageUrl)
                    }
                    else toast.error(response?.data?.msg)
                } catch (error) {
                    toast.error("Something went wrong with the image. Please try again!");
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }
    const updateFormData = async () => {
        try {
            if (formData?.imageUrl) {
                const apiService = new ApiService();
                let body = {
                    record_id: formData?.companyId,
                    companyId: formData?.recordId,
                    companyName: formData?.name,
                    companyPO: formData?.pObox,
                    companyAdd1: formData?.address,
                    companyCity: formData?.city?.label,
                    companyCountry: formData?.country?.label,
                    companyTel: formData?.phoneNumber,
                    companyFax: formData?.fax,
                    companyEmail: formData?.email,
                    trn: formData.trn,
                    companyWeb: formData?.web,
                    companyLogo: formData.imageUrl,
                    companyRemarks: formData?.remarks,
                    isActive: true,
                }
                const res = await apiService.put(`UPDATE_CATEGORY`, body);
                if (res?.data?.statusCode === 201) {
                    toast.success('Form data updated successfully');
                    setFormData({
                        recordId: "",
                        name: "",
                        phoneNumber: "",
                        pObox: "",
                        web: '',
                        fax: '',
                        email: '',
                        trn: '',
                        images: [],
                        imageUrl: "",
                    })
                    window.history.replaceState({}, '')
                    fetchCategoryData()
                    setSelectedCity(null)
                    setSelectedCountry(null)
                    setSelectedRow(null)
                    setSelectedState(null)
                } else {
                    console.error('Failed to update form data');
                }
            } else {
                toast.warning('Upload Image again');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    }
    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    }
    const handleImageRemove = (index) => {
        const updatedImages = formData.images ? [...formData.images] : [];
        updatedImages.splice(index, 1);
        setFormData({
            ...formData,
            images: updatedImages,
            imageUrl: "",
        });
    }
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedCity(null);
    }
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    }
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
    }
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            name: "",
            phoneNumber: "",
            pObox: "",
            web: '',
            fax: '',
            email: '',
            trn: '',
            images: [],
            address: '',
            imageUrl: "",
        })
        fetchCategoryData()
        setSelectedCity(null)
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedRow(null)
    }
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Company Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className='col center'>
                        {selectedRow ? (
                            <button className='mainbtn ' onClick={handleUpdateImg}><FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                        ) : (
                            <>{canCreateMaster === true ?
                                <button className='mainbtn ' type="submit" onClick={() => UploadImage()}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                :
                                <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            } </>
                        )}
                    </div>
                    <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-3'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className="form-group row pb-3">
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start"> Code</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="recordId" onWheel={(e) => e.target.blur()}
                                disabled={canCreateMaster === true ? false : true}
                                value={formData.recordId}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start"> Name *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">P.O.Box</label>
                            <input
                                type="number" onWheel={(e) => e.target.blur()}
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="pObox"
                                value={formData.pObox}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Phone Number</label>
                            <input
                                type="number" onWheel={(e) => e.target.blur()}
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row justify-content-between pb-3">
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Web</label>
                            <input
                                type="url"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="web"
                                value={formData.web}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Fax No</label>
                            <input
                                type="number" onWheel={(e) => e.target.blur()}
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="fax" min={0}
                                value={formData.fax}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Email</label>
                            <input
                                type="email"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">TRN</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                disabled={canCreateMaster === true ? false : true}
                                id="validationCustom01"
                                name="trn"
                                value={formData.trn}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="form-group pb-3 col-6 ">
                            <div className="pb-3 row d-flex justify-content-between">
                                <div className="col-6 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Address</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        disabled={canCreateMaster === true ? false : true}
                                        id="validationCustom01"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="col-6 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Country</label>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-75 p-0"
                                        value={selectedCountry}
                                        isDisabled={canCreateMaster === true ? false : true}
                                        onChange={handleCountryChange}
                                        options={countriesList?.map(country => ({ value: country, label: country?.name }))}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <div className="col-6 d-flex justify-content-between ">
                                    <label htmlFor="validationCustom19" className="form-label  pt-2 start">State</label>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-75 p-0"
                                        value={selectedState}
                                        isDisabled={canCreateMaster === true ? false : true}
                                        onChange={handleStateChange}
                                        options={stateList ? stateList?.map(state => ({ value: state, label: state?.name })) : []}
                                    />
                                </div>
                                <div className="col-6 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">City</label>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-75 p-0"
                                        isDisabled={canCreateMaster === true ? false : true}
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                        options={cityList ? cityList?.map(city => ({ value: city, label: city?.name })) : []}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <FileUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} canCreateMaster={canCreateMaster} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                {loading ? (
                    <div className='center'>
                        <PropagateLoader color="#6E5199" size={15} />
                    </div>
                ) : (
                    <>
                        <CompanyGridMaster rowData={rowData?.result} fetchCategoryData={fetchCategoryData} canEditMaster={canEditMaster}
                            setSelectedRow={setSelectedRow} />
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchCategoryData} />
                    </>
                )}
            </div>
        </div>
    )
}
export default CompanyMaster