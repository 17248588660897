import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MessageGrid from '../purchaseRequisition/messageGrid';
import ItemGridGRN from '../Goods&Receipt/grnList/itemGrid';
import { toast } from 'react-toastify';
import AuthorizationGrn from '../Goods&Receipt/authorization';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import DirectPurchaseGrid from './directPurchaseGrid';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';

const UpdateDirectPurchase = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const [POData, setPOData] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [Department, setDepartment] = useState([]);
    const [WareHouse, setWareHouse] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const location = useLocation();
    const [supplierCheck, setSupplierCheck] = useState("Supplier");
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const userName = useSelector(getName);
    const compareData = location.state;
    const [OtherTab, setOtherTab] = useState(false);
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        const item = location.state;
        if (item.docno) {
            const getGrn = async () => {
                try {
                    const response = await apiService.get('GET_GRN');
                    const selectedGrn = response.data.result?.find(rec => rec.grnNo === item.docno)
                    const selectedDepartment = Department?.find(dep => dep?.description === selectedGrn?.department)
                    const selectedLocation = WareHouse?.find(dep => dep?.description === selectedGrn?.warehouse)
                    const SelectedSupplier = supplier?.find(dep => dep?.supplierName === selectedGrn?.vendorName)
                    const formattedInvDate = selectedGrn?.invoiceDate ? formatDateToDatetimeLocal(selectedGrn?.invoiceDate) : getCurrentLocalDatetime();
                    const formattedDelivDate = selectedGrn?.delivery_Date ? formatDateToDatetimeLocal(selectedGrn?.delivery_Date) : getCurrentLocalDatetime();
                    setFormData({
                        GRNNO: selectedGrn?.grnNo,
                        deliveryNote: "",
                        remarks: selectedGrn?.remarks,
                        InvoiceNo: selectedGrn?.invoiceNo,
                        InvoiceDate: formattedInvDate,
                        deliveryDate: formattedDelivDate,
                        PropertyGroup: selectedGrn?.costCenter1,
                        TaxInvoice: selectedGrn?.remarks,
                        Property: selectedGrn?.costCenter2,
                        Outlet: selectedGrn?.costCenter3,
                        Currency: selectedGrn?.currencyCode,
                        exchangeRate: SelectedCurrency?.value?.conversionRate,
                        Department: { label: selectedDepartment?.description, value: selectedDepartment },
                        location: { label: selectedLocation?.description, value: selectedLocation },
                        vendorName: { label: SelectedSupplier?.supplierName, value: SelectedSupplier },
                        vendorId: { label: SelectedSupplier?.record_Id, value: SelectedSupplier },
                    });
                    setIsVatExcluded(selectedGrn.vatInclusive)
                    setPOData(selectedGrn)
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            getGrn()
        } else {
            const selectedDepartment = Department?.find(dep => dep?.description === item?.department)
            const selectedLocation = WareHouse?.find(dep => dep?.description === item?.warehouse)
            const SelectedSupplier = supplier?.find(dep => dep?.supplierName === item?.vendorName)
            const formattedInvDate = item?.invoiceDate ? formatDateToDatetimeLocal(item?.invoiceDate) : getCurrentLocalDatetime();
            const formattedDelivDate = item?.delivery_Date ? formatDateToDatetimeLocal(item?.delivery_Date) : getCurrentLocalDatetime();
            setFormData({
                GRNNO: item?.grnNo,
                deliveryNote: "",
                remarks: item?.remarks,
                InvoiceNo: item?.invoiceNo,
                InvoiceDate: formattedInvDate,
                deliveryDate: formattedDelivDate,
                PropertyGroup: item?.costCenter1,
                TaxInvoice: item?.remarks,
                Property: item?.costCenter2,
                Outlet: item?.costCenter3,
                Currency: item?.currencyCode,
                exchangeRate: SelectedCurrency?.value?.conversionRate,
                Department: { label: selectedDepartment?.description, value: selectedDepartment },
                location: { label: selectedLocation?.description, value: selectedLocation },
                vendorName: { label: SelectedSupplier?.supplierName, value: SelectedSupplier },
                vendorId: { label: SelectedSupplier?.record_Id, value: SelectedSupplier },
            });
            setIsVatExcluded(item.vatInclusive)
            setPOData(item)
        }
    }, [location?.state, Department, WareHouse, supplier]);

    useEffect(() => {
        fetchData()
        getApprovalUser()
        getCurrency()

    }, [])
    const handleVatChange = () => {
        setIsVatExcluded(!isVatExcluded);
    };
    let param = `?isActive=true`
    const fetchData = async () => {
        const Supplier = await apiService.get("GET_SUPPLIER");
        setSupplier(Supplier?.data?.result);
        const department = await apiService.get("GET_DEPARTMENT_MASTER");
        setDepartment(department?.data?.result);
        const WareHouse = await apiService.get("GET_WAREHOUSE_MASTER");
        setWareHouse(WareHouse?.data?.result);
    }
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const getApprovalUser = async () => {
        try {
            const apiService = new ApiService();
            let param = `?Type=${"GRN"}&Company=PI&Department=IT`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const [formData, setFormData] = useState({
        GRNNO: "",
        deliveryNote: "",
        remarks: "",
        InvoiceNo: "",
        InvoiceDate: "",
        deliveryDate: "",
        PropertyGroup: "",
        TaxInvoice: "",
        Property: "",
        Department: "",
        Outlet: "",
        Currency: "",
        exchangeRate: SelectedCurrency?.value?.conversionRate,
        deliveryPoint: "",
        PurchaseOrderDate: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        email: '',
        subcatname: "",
        location: [],
        vendorName: [],
        vendorId: []
    });

    const staticOptions = [];
    const handleDepartmentChange = (selectedOption) => {
        setFormData({ ...formData, Department: selectedOption?.description });
    }
    const handleLocationChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const CreateGRN = async () => {
        if (!formData.location) {
            toast.warning("Enter location");
            return;
        }
        if (!formData?.vendorName?.value?.supplierName) {
            toast.warning("Select Supplier");
            return;
        }
        if (ItemData.length === 0) {
            toast.warning("Please add items");
            return;
        }
        for (const item of ItemData) {
            if (!item.qty) {
                toast.warning(`Please enter ${item.itemDescription} quantity`);
                return;
            } else if (!item.baseAmount) {
                toast.warning(`Please enter ${item.itemDescription} Unit Price`);
                return;
            }
        }
        try {
            const totalAmount = ItemData.reduce((sum, row) => sum + (row.amount || 0), 0);
            const vatAmount = ItemData.reduce((sum, row) => sum + (row.vatAmount || 0), 0);
            const currentDatetimeUTC = new Date().toISOString();
            const GRNDetailsData = ItemData.map((item, index) => {
                const grossAmount = parseFloat(item.amount) - parseFloat(item.vatAmount || 0);
                debugger
                return {
                    requisitionNo: item?.requisitionNo,
                    srno: index + 1,
                    itemCode: item?.ItemNo,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: parseInt(item.qty),
                    baseUOM: item?.uom,
                    baseQty: parseInt(item.baseQty),
                    specification: item.specification,
                    convValue: parseFloat(item?.baseQty),
                    unitPrice: parseFloat(item.baseAmount),
                    amount: parseFloat(item.amount),
                    baseAmount: parseFloat(item.amount),
                    convToInvUOM: parseInt(item.baseQty),
                    importedQty: 0,
                    prodDate: currentDatetimeUTC,
                    expDate: item?.expDate || currentDatetimeUTC,
                    isActive: true,
                    returnQty: 0,
                    invoicedQty: 0,
                    extraQty: 0,
                    grossAmount: grossAmount,
                    grossBaseAmount: grossAmount,
                    addCharges: parseFloat(formData.addCharges),
                    vatInclusive: isVatExcluded,
                    vatCode: 1,
                    vatDesc: item.vatDes,
                    vatAmount: item.vatAmount,
                    vatUnitPrice: item.vatAmount / item.qty,
                    vatPerc: item.vat,
                    vatBaseAmount: item.vatAmount,
                    inventoryAccountCode: item?.inventoryAccountCode,
                    inventoryAccountName: item?.inventoryAccountName,
                }
            });
            const userData = {
                grnNo: formData.GRNNO,
                pOrderNo: formData.GRNNO,
                delivery_Date: formData.deliveryDate,
                status: 0,
                invoiceDate: formData.InvoiceDate,
                remarks: formData?.remarks,
                modifiedOn: currentDatetimeUTC,
                modifiedBy: userName,
                vendorId: formData.vendorId?.label.toString(),
                vendorName: formData.vendorName?.label,
                terms: formData.vendorName.value.paymentTerm,
                department: formData?.Department,
                costCenter1: formData.costCenter1?.label,
                costCenter2: formData.costCenter2?.label,
                costCenter3: formData.costCenter3?.label,
                costCenter4: formData.costCenter4?.label,
                isActive: true,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                grnDetailsDtos: GRNDetailsData,
                warehouse: formData?.location.label,
                transport_Mode: "Direct",
                transporter: formData?.vendorName.label,
                invoiceRef: formData.deliveryNote,
                amount: parseFloat(totalAmount),
                baseAmount: parseFloat(totalAmount),
                vatInclusive: isVatExcluded,
                vatAmount: vatAmount,
                grossAmount: parseFloat(totalAmount),
                grossBaseAmount: parseFloat(totalAmount),
            };
            debugger
            // const response = await apiService.post("CREATE_GRN", userData);

            // if (response?.data?.statusCode === 201) {
            //     const userData2 = {
            //         docType: "GRN",
            //         docId: formData.GRNNO,
            //         department: "IT",
            //         company: "PI",
            //     };
            //     const docBody = {
            //         docId: "GRN",
            //         typeOfDoc: "GRN",
            //     };
            //     const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
            //     const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
            //     toast.success(`${formData.GRNNO} Created successfully`);
            //     navigate("/inventory/DirectPurchase")
            // }
        } catch (error) {
            console.error("Error Creating PR:", error);
        }
    };
    const handleSupplierChange = (selectedOption) => {
        setFormData({
            ...formData,
            vendorName: { label: selectedOption.value.supplierName, value: selectedOption },
            vendorId: { label: selectedOption.value.record_Id, value: selectedOption },
        });
    }
    const handleSuppChange = (val) => {
        setSupplierCheck(val);
    };
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Direct Purchase</h5>
                <div className='d-flex gap-3'>
                    {compareData?.eye === true ? "" :
                        <button className='mainbtn ' type="submit" onClick={CreateGRN}><FaSave className='center mt-1 mx-2' /><div>Update</div></button>
                    }
                    {compareData?.eye === true ? "" :
                        <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    }
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Purchase Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="GRNNO" className="form-label  pt-2 start">DP GRN No</label>
                                <input
                                    className="form-Input w-70"
                                    value={formData.GRNNO}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">GRN Date</label>
                                <input className='form-Input w-70' type='datetime-local' min={currentDatetimeUTC}
                                    name="deliveryDate"
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                    disabled={compareData.eye === true ? true : false}
                                />
                            </div>
                            <div className='col center'>
                                <button className='mainbtn ' >Advance Search</button>
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceNo" className="form-label  pt-2 start">Invoice No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="InvoiceNo"
                                    value={formData.InvoiceNo}
                                    disabled={compareData.eye === true ? true : false}
                                    onChange={handleInputChange}
                                    name="InvoiceNo"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceDate" className="form-label  pt-2 start">Invoice Date</label>
                                <input
                                    type='datetime-local' min={currentDatetimeUTC}
                                    className="form-Input w-70"
                                    id="InvoiceDate"
                                    value={formData.InvoiceDate}
                                    onChange={handleInputChange}
                                    disabled={compareData.eye === true ? true : false}
                                    name="InvoiceDate"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="itemCode" className="form-label  pt-2 start">Item No</label>
                                <input type='text'
                                    className="form-Input w-70" name='itemCode'
                                    value={formData.itemCode}
                                    disabled={compareData.eye === true ? true : false}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="TaxInvoice" className="form-label  pt-2 start">Tax Invoice No ( KRA )</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="TaxInvoice"
                                    value={formData.TaxInvoice}
                                    disabled={compareData.eye === true ? true : false}
                                    onChange={handleInputChange}
                                    name="TaxInvoice"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="deliveryNote" className="form-label  pt-2 start">Delivery Note No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="deliveryNote"
                                    disabled={compareData.eye === true ? true : false}
                                    value={formData.deliveryNote}
                                    onChange={handleInputChange}
                                    name="deliveryNote"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Item Description</label>
                                <input className='form-Input w-70' type='text'
                                    name="itemDescription"
                                    value={formData.itemDescription}
                                    disabled={compareData.eye === true ? true : false}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Delivery Type</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled={compareData.eye === true ? true : false}
                                    options={staticOptions}
                                    onChange={(selectedOption) => handleInputChange('PropertyGroup', selectedOption)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Location" className="form-label  pt-2 start">Location</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.location}
                                    isDisabled={compareData.eye === true ? true : false}
                                    onChange={handleLocationChange}
                                    options={WareHouse?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="col">
                                <div className="d-flex p-1 brderbox gap-2 justify-content-around " style={{ background: "#d9d9d95e" }}>
                                    <div className="form-check gap-3 center">
                                        <input style={{ width: "20px", height: "20px" }}
                                            disabled={compareData.eye === true ? true : false}
                                            onClick={() => handleSuppChange("Supplier")} checked={supplierCheck === "Supplier" ? true : false}
                                            className="form-check-input" type="radio" name="flexRadioDefault" id={supplierCheck} />
                                        <label className="form-check-label" for="Supplier">Supplier</label>
                                    </div>
                                    <div className="form-check gap-3 center" >
                                        <input className="form-check-input" style={{ width: "20px", height: "20px" }}
                                            disabled={compareData.eye === true ? true : false}
                                            type="radio" name="flexRadioDefault" id={supplierCheck} checked={supplierCheck === "CashPurchase" ? true : false}
                                            onClick={() => handleSuppChange("CashPurchase")}
                                        />
                                        <label className="form-check-label" for="CashPurchase">Cash Purchase</label>
                                    </div>
                                    <div className="form-check gap-3 center" >
                                        <input className="form-check-input" style={{ width: "20px", height: "20px" }}
                                            disabled={compareData.eye === true ? true : false}
                                            type="radio" name="flexRadioDefault" id={supplierCheck} checked={supplierCheck === "sample" ? true : false}
                                            onClick={() => handleSuppChange("sample")}
                                        />
                                        <label className="form-check-label" for="sample">sample</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="Outlet" className="form-label  pt-2 start">Add Charges</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="Outlet"
                                    disabled={compareData.eye === true ? true : false}
                                    value={formData.Outlet}
                                    onChange={handleInputChange}
                                    name="Outlet"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Supplier Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Supplier</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled={compareData.eye === true ? true : false}
                                    value={formData.vendorName}
                                    onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    options={supplier?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Supplier ID</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled={compareData.eye === true ? true : false}
                                    value={formData.vendorId}
                                    onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    options={supplier?.map((sup) => ({ value: sup, label: sup?.record_Id }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Currency" className="form-label  pt-2 start">Currency </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isSearchable={false}
                                    isDisabled={compareData.eye === true ? true : false}
                                    value={SelectedCurrency}
                                    onChange={(e) => handlecurChange(e)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="ExchangeRate" className="form-label  pt-2 start">Exchange Rate </label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    isDisabled={compareData.eye === true ? true : false}
                                    id="exchangeRate"
                                    value={SelectedCurrency?.value?.conversionRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Property Group</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled={compareData.eye === true ? true : false}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    options={staticOptions}
                                    onChange={(selectedOption) => handleInputChange('PropertyGroup', selectedOption)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Property" className="form-label  pt-2 start">Property</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="Property"
                                    disabled={compareData.eye === true ? true : false}
                                    value={formData.Property}
                                    onChange={handleInputChange}
                                    name="Property"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Department" className="form-label  pt-2 start">Department</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled={compareData.eye === true ? true : false}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.Department}
                                    onChange={handleDepartmentChange}
                                    options={Department?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Department" className="form-label  pt-2 start">Outlets</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    isDisabled={compareData.eye === true ? true : false}
                                    className="w-70 p-0"
                                    value={formData.Department}
                                    onChange={handleDepartmentChange}
                                    options={Department?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setOtherTab(!OtherTab)}>
                    <div className="h6 pt-1">Other Details</div>
                </div>
                <div className={`slide-content ${OtherTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className='d-flex justify-content-between '>
                            <div className="d-flex justify-content-between col-md-4">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Remarks</label>
                                <input
                                    name="remarks"
                                    type='text'
                                    className="form-Input w-70"
                                    id="subcatname"
                                    disabled={compareData.eye === true ? true : false}
                                    value={formData.remarks}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-md-2 center brderbox'><a>Attachment</a></div>
                            <div className="col-md-3 d-flex gap-2 justify-content-around">
                                <div className="form-check">
                                    <input
                                        style={{ width: "26px", height: "26px" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={isVatExcluded}
                                        disabled={compareData?.eye === true ? true : false}
                                        onChange={handleVatChange}
                                    />
                                    <label className="form-check-label mx-2 mt-1">
                                        Vat Excluded
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-2 mb-3">
                {gridVisibility[0] && (
                    <DirectPurchaseGrid onRequisitionData={handleRequisitionData} itemData={POData || compareData} selectedCheck={isVatExcluded}/>
                )}
                {gridVisibility[1] && (
                    <AuthorizationGrn handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={POData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {gridVisibility[3] && (
                    <ItemGridGRN onRequisitionData={handleRequisitionData} />
                )}
            </div>
        </div >
    )
}

export default UpdateDirectPurchase