import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import ApiService from '../../../INVENTORY/services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import '../../../POS/component/pages/menus/addItems/addmenu.css'
import { ClipLoader, FadeLoader } from 'react-spinners';
import { getName, getUserId } from '../../../Redux/selecters';
import { useSelector } from 'react-redux';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa6";

const CreateItem = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const val = location?.state;
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [DirectPurchase, setDirectPurchase] = useState(false);
  const [unitMeasurement, setunitMeasurement] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [packingUom, setPackingUOMOptions] = useState([]);
  const [FinishGood, setFinishGoods] = useState(false);
  const [packingType, setPackingType] = useState([]);
  const [stockType, setStockType] = useState([]);
  const [Service, setService] = useState(false);
  const [Assests, setAssets] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [Active, setActive] = useState(true);
  const [loader, setLoader] = useState(false);
  const [vat, setvat] = useState([]);
  const userName = useSelector(getName);
  const [accounts, setAccounts] = useState();
  const userId = useSelector(getUserId);
  const [ItemCode, setItemCode] = useState()
  const [itemDetais, setItemDetais] = useState(true);
  const [glDetails, setglDetails] = useState(false);
  const [formData, setFormData] = useState({
    itemNo: '',
    recordId: '',
    Vat: [],
    volume: '',
    length: "",
    width: "",
    description: "",
    PackingUnitMeasurement: [],
    InventoryUom: [],
    Supplier: [],
    PackingType: [],
    height: "",
    fixedAsset: "",
    purchasePrice: 0,
    area: "",
    address: "",
    weight: "",
    Category: [],
    SubCategory: [],
    StockType: [],
    enableLoyalty: true,
    adjustmentAccount: "",
    grnAccount: "",
    cgsAccount: "",
    wastageAccount: "",
    InventoryAccounts: "",
    address02: "",
    status: true,
    creationDate: "",
    size: '',
    shape: '',
    reorderLevel: "",
    salePrice: 0,
    minLevel: 0,
    thickness: 0,
    maxLevel: 0,
    convToInvUOM: ''
  })
  const resetFormData = () => {
    setFormData({
      itemNo: null,
      Vat: null,
      reorderLevel: null,
      volume: null,
      length: null,
      width: null,
      description: null,
      PackingUnitMeasurement: null,
      Supplier: null,
      PackingType: null,
      height: null,
      fixedAsset: null,
      purchasePrice: null,
      area: null,
      address: null,
      weight: null,
      Category: null,
      SubCategory: null,
      enableLoyalty: null,
      CGS: null,
      address02: null,
      status: null,
      creationDate: null,
      thickness: null,
      size: null,
      shape: null,
      salePrice: null,
      minLevel: null,
      maxLevel: null,
      InventoryUom: null,
      convToInvUOM: null
    });
  };
  useEffect(() => {
    const item = location.state;
    const setData = async () => {
      try {
        const apiService = new ApiService();
        const selectedCategory = categoryOptions?.find(cat => cat?.catCode === item?.category);
        let param = `?isActive=true&Catcode=${selectedCategory.catCode}`
        const subCategoryResponse = await apiService.getApiParamater("GET_SUB_CATEGORY", param);
        let glparam = `?ItemNo=${item?.itemNo}`
        const getGLAccounts = await apiService.getApiParamater("GET_ITEM_GL", glparam);
        setItemCode(item?.itemNo)
        debugger
        let itemGl = getGLAccounts?.data?.result[0]
        const selectedVat = vat?.find(v => v.description === item?.vat);
        const selectedSuplier = supplier?.find(sp => sp?.record_Id === parseInt(item?.supplier));
        const selectedPackingType = packingType?.find(pt => pt?.description === item?.packingType);
        const selectedSubCategory = subCategoryResponse.data.result?.find(cat => cat?.subcatcode === item?.subCategory);
        const selectedStocktype = stockType?.find(stock => stock?.stockTypeCode === item?.stockType);
        const selectedInvUOM = unitMeasurement?.find(InventoryUM => InventoryUM?.description === item?.unitMeasurement);
        const selectedPUM = packingUom?.find(p => p?.description === item?.baseUnit);
        const selectedInvntory = accounts?.find(inv => inv.accountCode === itemGl?.inventoryAccountCode)
        const selectedAdjustmentAccount = accounts?.find(adj => adj.accountCode === itemGl?.adjustmentAccountCode)
        const selectedgrnAccount = accounts?.find(adj => adj.accountCode === itemGl?.grnAccountCode)
        const selectedcgsAccount = accounts?.find(adj => adj.accountCode === itemGl?.cgsAccountCode)
        const selectedwastageAccount = accounts?.find(adj => adj.accountCode === itemGl?.wastageAccountCode)
        setFormData({
          description: item?.itemDescription || "",
          itemNo: item?.recordId || "",
          volume: item?.volume || '',
          length: item?.length || '',
          width: item?.width || '',
          thickness: item?.thickness || '',
          height: item?.height || "",
          createdOn: moment(item?.createdOn).format("YYYY-MM-DD") || "",
          weight: item?.weight || "",
          purchasePrice: item?.purchasePrice || "",
          area: item?.area || "",
          address: item?.address || "",
          size: item?.size || "",
          shape: item?.shape || "",
          enableLoyalty: item?.enableLoyalty || 0,
          minLevel: item?.minLevel || 0,
          maxLevel: item?.maxLevel || 0,
          reorderLevel: item?.reorderLevel,
          salePrice: parseInt(formData?.salePrice),
          convToInvUOM: item?.convToInvUOM || 0,
          Vat: { label: selectedVat?.description, value: selectedVat },
          PackingType: { label: selectedPackingType?.description, value: selectedPackingType },
          Supplier: { label: selectedSuplier?.supplierName, value: selectedSuplier },
          Category: { label: selectedCategory?.catName, value: selectedCategory },
          SubCategory: { label: selectedSubCategory?.subcatname, value: selectedSubCategory },
          StockType: { label: selectedStocktype?.stockTypeName, value: selectedStocktype },
          InventoryUom: { label: selectedInvUOM?.description, value: selectedInvUOM },
          PackingUnitMeasurement: { label: selectedPUM?.description, value: selectedPUM },
          adjustmentAccount: { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
          grnAccount: { label: selectedgrnAccount?.accountName, value: selectedgrnAccount },
          cgsAccount: { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
          wastageAccount: { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
          InventoryAccounts: { label: selectedInvntory?.accountName, value: selectedInvntory },
        });
        setAssets(item?.fixedAsset)
        setActive(item?.isActive)
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (item) {
      setData()
    }
  }, [location?.state, vat, packingUom, packingType, supplier, categoryOptions, subCategories, stockType, unitMeasurement, accounts]);

  useEffect(() => {
    // Fetch dropdown data from API when the component mounts
    const fetchDropdownData = async () => {
      try {
        const apiService = new ApiService();
        const apiService2 = new ApiService2();
        let param = `?isActive=true`
        // Fetch vat options
        const vat = await apiService.getApiParamater('GET_INV_TAX', param);
        setvat(vat.data?.result);
        // Fetch inventory UOM options
        const unitMeasurementResponse = await apiService.getApiParamater("GET_EUNIT_MEASUREMENT", param);
        setunitMeasurement(unitMeasurementResponse?.data?.result);
        const Supplier = await apiService.getApiParamater("GET_SUPPLIER", param);
        setSupplier(Supplier?.data?.result.reverse());
        // Fetch category options
        const categoryResponse = await apiService.getApiParamater("GET_CATEGORY", param);
        setCategoryOptions(categoryResponse?.data?.result);
        const subCategoryResponse = await apiService.getApiParamater("GET_SUB_CATEGORY", param);
        setSubCategories(subCategoryResponse.data.result)
        // Fetch packing Type
        const packingResponse = await apiService.getApiParamater("GET_EPACKING", param);
        setPackingType(packingResponse?.data?.result);
        // Fetch stock type options
        const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
        setStockType(stockType?.data?.result);
        // Fetch packing UOM options
        const packingUOMResponse = await apiService.getApiParamater("GET_EUNIT_MEASUREMENT", param);
        setPackingUOMOptions(packingUOMResponse?.data?.result);
        // Account options
        const accountResponse = await apiService.get('GET_ACCOUNTS');
        setAccounts(accountResponse.data.result)
        // Add more API calls to fetch other dropdown options
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
        // Handle error as needed
      }
    };
    fetchDropdownData();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      [`${name}IsValid`]: !!value
    });
  }
  const handlevatChange = (selectedOption) => {
    setFormData({ ...formData, Vat: selectedOption });
  }
  const handleUnitMeasurementChange = (selectedOption) => {
    setFormData({ ...formData, PackingUnitMeasurement: selectedOption });
  }
  const handleIUtMeasurementChange = (selectedOption) => {
    setFormData({ ...formData, InventoryUom: selectedOption });
  }
  const handleSupplierChange = (selectedOption) => {

    setFormData({ ...formData, Supplier: selectedOption });
  }
  const handlePackingTypeChange = (selectedOption) => {
    setFormData({ ...formData, PackingType: selectedOption });
  }
  const handleCategoryChange = (selectedOption) => {
    const selectedInvntory = accounts?.find(inv => inv.accountCode === selectedOption?.value?.inventoryAccountCode)
    const selectedAdjustmentAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.adjustmentAccountCode)
    const selectedgrnAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.grnAccountCode)
    const selectedcgsAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.cgsAccountCode)
    const selectedwastageAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.wastageAccountCode)
    setFormData({
      ...formData,
      Category: selectedOption,
      adjustmentAccount: { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
      grnAccount: { label: selectedgrnAccount?.accountName, value: selectedgrnAccount },
      cgsAccount: { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
      wastageAccount: { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
      InventoryAccounts: { label: selectedInvntory?.accountName, value: selectedInvntory },
    });
    handleSubcat(selectedOption)
  }
  const handleSubCategoryChange = (selectedOption) => {
    const selectedInvntory = accounts?.find(inv => inv.accountCode === selectedOption?.value?.inventoryAccountCode)
    const selectedAdjustmentAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.adjustmentAccountCode)
    const selectedgrnAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.grnAccountCode)
    const selectedcgsAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.cgsAccountCode)
    const selectedwastageAccount = accounts?.find(adj => adj.accountCode === selectedOption?.value?.wastageAccountCode)
    setFormData({
      ...formData,
      adjustmentAccount: formData.adjustmentAccount?.value ? formData.adjustmentAccount : { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
      grnAccount: formData.grnAccount?.value ? formData.grnAccount : { label: selectedgrnAccount?.accountName, value: selectedgrnAccount },
      cgsAccount: formData.cgsAccount?.value ? formData.cgsAccount : { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
      wastageAccount: formData.wastageAccount?.value ? formData.wastageAccount : { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
      InventoryAccounts: formData.InventoryAccounts?.value ? formData.InventoryAccounts : { label: selectedInvntory?.accountName, value: selectedInvntory },
      SubCategory: selectedOption
    });
  }
  const handleStockChange = (selectedOption) => {
    setFormData({ ...formData, StockType: selectedOption });
  }
  const handleActiveSelection = (e) => {
    setActive(e);
  }
  const handleDirectPurchaseSelection = (e) => {
    setDirectPurchase(e);
  }
  const handleAssetsSelection = (e) => {
    setAssets(e);
  }
  const handleFinishGoodSelection = (e) => {
    setFinishGoods(e);
  }
  const handleServiceSelection = (e) => {
    setService(e);
  }
  const handleAddItem = async (e) => {
    e.preventDefault();
    debugger
    setLoader(true)
    if (!formData.description || !formData.InventoryUom || !formData?.Vat || !formData?.PackingType || !formData?.Category || !formData?.SubCategory || !formData?.StockType || !formData?.convToInvUOM || !formData?.PackingUnitMeasurement) {
      toast.warning('Please fill in all required fields');
      if (!formData.Vat) { toast.warning("Enter Select VAT") }
      if (!formData.description) { toast.warning("Enter Description") }
      if (!formData.InventoryUom) { toast.warning("Enter Inventory Uom") }
      if (!formData.PackingType?.label) { toast.warning("Enter Select Packing Type") }
      if (!formData.Category?.value?.catCode) { toast.warning("Enter Select Category") }
      if (!formData.PackingUnitMeasurement?.label) { toast.warning("Enter Packing UOM") }
      if (!formData.SubCategory?.value?.subcatcode) { toast.warning("Enter Select Sub Category") }
      if (!formData.StockType?.value?.stockTypeCode) { toast.warning("Enter Select Stock Type") }
      if (!formData.convToInvUOM) { toast.warning("Enter Enter Conversion Rate") }
      if (!formData?.cgsAccount?.value?.accountCode) { toast.warning("Select CGS AccountCode") }
      setLoader(false)
      return;
    }
    try {
      setLoader(true)
      const body = {
        itemNo: ItemCode,
        itemDescription: formData?.description,
        purchasePrice: parseInt(formData?.purchasePrice || 0),
        salePrice: parseInt(formData?.salePrice),
        minLevel: parseInt(formData?.minLevel),
        maxLevel: parseInt(formData?.maxLevel),
        supplier: formData?.Supplier?.value?.record_Id.toString() || '',
        vat: formData.Vat?.value.description,
        category: formData?.Category?.value.catCode.toString(),
        subCategory: formData.SubCategory?.value.subcatcode.toString(),
        stockType: formData.StockType?.value.stockTypeCode.toString(),
        unitMeasurement: formData?.InventoryUom?.label,
        packingType: formData?.PackingType?.label,
        primaryUOM: "0",
        size: formData?.size,
        shape: formData?.shape,
        weight: formData?.weight,
        fixedAsset: Assests.toString() || '',
        // inventory: formData.StockType?.label === "Inventory" ? "True" : "False",
        inventory: "True",
        sales: "False",
        loggedInUser: userId,
        width: formData?.width,
        thickness: formData?.thickness.toString(),
        height: formData?.height,
        length: formData?.length,
        volume: formData?.volume,
        reorderLevel: formData?.reorderLevel,
        convToInvUOM: formData?.convToInvUOM,
        convToPriUOM: 0,
        remarks2: "0",
        warehouseLocation: "0",
        binLocation: "0",
        finish: "False",
        purchased: "False",
        consumables: "0",
        food: "0",
        packingUOM: formData?.PackingUnitMeasurement?.label || "",
        exp9: "1",
        inventoryAcCode: formData?.InventoryAccounts?.value?.accountCode || "",
        inventoryAcName: formData?.InventoryAccounts?.label || "",
        grnAcCode: formData?.grnAccount?.value?.accountCode || "",
        grnAcName: formData?.grnAccount?.label || "",
        invoiceAcName: "0",
        invoiceAcCode: "0",
        cgsAcCode: formData?.cgsAccount?.value?.accountCode || "",
        cgsAcName: formData?.cgsAccount?.label || "",
        adjAcCode: formData?.adjustmentAccount?.value?.accountCode || "",
        adjAcName: formData?.adjustmentAccount?.label || "",
        wastageAccountCode: formData?.wastageAccount?.value?.accountCode || "",
        wastageAccountName: formData?.wastageAccount?.label || "",
        workInProgressAccountCode: "0",
        workInProgressAccountName: "0",
        issueAccountCode: "0",
        issueAccountName: "0",
      }
      const glBody = {
        itemNo: ItemCode,
        inventoryAccountCode: formData?.InventoryAccounts?.value?.accountCode || "",
        inventoryAccountName: formData?.InventoryAccounts?.label || "",
        grnAccountCode: formData?.grnAccount?.value?.accountCode || "",
        grnAccountName: formData?.grnAccount?.label || "",
        invoiceAccountCode: "0",
        invoiceAccountName: "0",
        cgsAccountCode: formData?.cgsAccount?.value?.accountCode || "",
        cgsAccountName: formData?.cgsAccount?.label || "",
        adjustmentAccountCode: formData?.adjustmentAccount?.value?.accountCode || "",
        adjustmentAccountName: formData?.adjustmentAccount?.label || "",
        wastageAccountCode: formData?.wastageAccount?.value?.accountCode || "",
        wastageAccountName: formData?.wastageAccount?.label || "",
        workInProgressAccountCode: "0",
        workInProgressAccountName: "0",
        issueAccountCode: "0",
        issueAccountName: "0",
      }
      debugger
      const apiService = new ApiService();
      let response = await apiService.post("CREATE_ITEM", body);
      let GLResponse = await apiService.post("CREATE_ITEM_GL", glBody);

      if (response.data.statusCode === 201) {
        toast.success('Item created successfully');
        navigate("/inventory/Master/Item")
        setItemCode()
        setLoader(false)
        resetFormData();
      } else if (response?.data?.statusCode === 400) {
        setLoader(false)
        toast.warning(response?.data?.message);
      } else if (response?.data?.statusCode === 208) {
        setLoader(false)
        toast?.error(response?.data?.message)
      }
    } catch (error) {
      setLoader(false)
      console.error('Error:', error);
    }
  }
  const handleSubcat = async (selectedOption) => {
    try {
      const apiService = new ApiService();
      let param = `?isActive=true&Catcode=${selectedOption?.value.catCode}`
      let itemparam = `?Category=${selectedOption?.value.catCode}`
      const subCategoryResponse = await apiService.getApiParamater("GET_SUB_CATEGORY", param);
      const ItemCodeResponse = await apiService.getApiParamater("GET_ITEM_CODE", itemparam);
      setSubCategoryOptions(subCategoryResponse?.data?.result);
      setItemCode(ItemCodeResponse.data.result[0].itemCode)
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const handleUpdateItem = async (e) => {
    e.preventDefault();
    setLoader(true)
    try {
      const body = {
        recordId: formData?.itemNo,
        itemNo: ItemCode,
        itemDescription: formData?.description,
        purchasePrice: parseInt(formData?.purchasePrice || 0),
        salePrice: parseInt(formData?.salePrice),
        minLevel: parseInt(formData?.minLevel),
        maxLevel: parseInt(formData?.maxLevel),
        supplier: formData?.Supplier?.value.record_Id.toString(),
        vat: formData.Vat?.value.description,
        category: formData?.Category?.value.catCode.toString(),
        subCategory: formData.SubCategory?.value.subcatcode.toString(),
        stockType: formData.StockType?.value.stockTypeCode.toString(),
        unitMeasurement: formData?.InventoryUom?.label,
        packingType: formData?.PackingType?.label,
        size: formData?.size,
        shape: formData?.shape,
        weight: formData?.weight,
        fixedAsset: Assests,
        inventory: formData.StockType.label === "Inventory" ? "True" : "False",
        loggedInUser: userId,
        width: formData?.width,
        thickness: formData?.thickness,
        height: formData?.height,
        length: formData?.length,
        volume: formData?.volume,
        reorderLevel: formData?.reorderLevel,
        convToInvUOM: formData?.convToInvUOM,
        packingUOM: formData.PackingUnitMeasurement.label,
        inventoryAcCode: formData?.InventoryAccounts?.value?.accountCode || "",
        inventoryAcName: formData?.InventoryAccounts?.label || "",
        grnAcCode: formData?.grnAccount?.value?.accountCode || "",
        grnAcName: formData?.grnAccount?.label || "",
        invoiceAcName: "0",
        invoiceAcCode: "0",
        cgsAcCode: formData?.cgsAccount?.value?.accountCode || "",
        cgsAcName: formData?.cgsAccount?.label || "",
        adjAcCode: formData?.adjustmentAccount?.value?.accountCode || "",
        adjAcName: formData?.adjustmentAccount?.label || "",
        wastageAccountCode: formData?.wastageAccount?.value?.accountCode || "",
        wastageAccountName: formData?.wastageAccount?.label || "",
        workInProgressAccountCode: "0",
        workInProgressAccountName: "0",
        issueAccountCode: "0",
        issueAccountName: "0",
      }
      const glBody = {
        itemNo: ItemCode,
        inventoryAccountCode: formData?.InventoryAccounts?.value?.accountCode || "",
        inventoryAccountName: formData?.InventoryAccounts?.label || "",
        grnAccountCode: formData?.grnAccount?.value?.accountCode || "",
        grnAccountName: formData?.grnAccount?.label || "",
        invoiceAccountCode: "0",
        invoiceAccountName: "0",
        cgsAccountCode: formData?.cgsAccount?.value?.accountCode || "",
        cgsAccountName: formData?.cgsAccount?.label || "",
        adjustmentAccountCode: formData?.adjustmentAccount?.value?.accountCode || "",
        adjustmentAccountName: formData?.adjustmentAccount?.label || "",
        wastageAccountCode: formData?.wastageAccount?.value?.accountCode || "",
        wastageAccountName: formData?.wastageAccount?.label || "",
        workInProgressAccountCode: "0",
        workInProgressAccountName: "0",
        issueAccountCode: "0",
        issueAccountName: "0",
      }
      debugger
      const apiService = new ApiService();
      let response = await apiService.put("UPDATE_ITEM", body);
      let GLResponse = await apiService.put("UPDATE_ITEM_GL", glBody);

      if (response.data.statusCode === 201) {
        setLoader(false)
        toast.success('Item created successfully');
        resetFormData();
        navigate("/inventory/Master/Item")
      } else if (response?.data?.statusCode === 400) {
        setLoader(false)
        toast.warning(response?.data?.message);
      } else if (response?.data?.statusCode === 208) {
        toast?.error(response?.data?.message)
        setLoader(false)
      }
    } catch (error) {
      console.error('Error:', error);
      setLoader(false)
    }
  }
  const handleAdjustmentAccountChange = (selectedOption) => {
    setFormData({ ...formData, adjustmentAccount: selectedOption });
  }
  const handleGRNAccountChange = (selectedOption) => {
    setFormData({ ...formData, grnAccount: selectedOption });
  }
  const handleCGSAccountChange = (selectedOption) => {
    setFormData({ ...formData, cgsAccount: selectedOption });
  }
  const handleWastageAccountChange = (selectedOption) => {
    setFormData({ ...formData, wastageAccount: selectedOption });
  }
  const handleInventoryAccountChange = (selectedOption) => {
    setFormData({ ...formData, InventoryAccounts: selectedOption });
  }
  const handleBackClick = () => {
    window.history.back();
  };
  return (
    <div className='px-4 pt-3'>
      <div className='d-flex justify-contant-between '>
        <h5 className='col mainInvHead'>Create Item</h5>
        <div className='d-flex justify-content-end gap-3'>
          <div className=''>
            {location.state ? (
              <>{loader === true ?
                <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                <>{val?.eye === true ? "" :
                  <button className='mainbtn ' onClick={handleUpdateItem} type="submit">Update</button>
                }</>
              }
              </>
            ) : (
              <>{loader === true ?
                <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                <button className='mainbtn ' onClick={handleAddItem} type="submit">
                  <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
              }
              </>
            )}
          </div>
          <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
          <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
        </div>
      </div>
      <div className=''>
        <form class="row g-3 needs-validation" novalidate>
          <div className="cost-center-section mb-2">
            <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
              onClick={() => setItemDetais(!itemDetais)}>
              <div className='h6 pt-1'>Item Detais</div>
            </div>
            <div className={`slide-content ${itemDetais ? 'open' : 'closed'}`}>
              <div className="border-box2 p-3">
                <div className="form-group justify-content-between row pb-3">
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom01" className="form-label  pt-1 start">Item No </label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      name="ItemCode"
                      disabled
                      value={ItemCode}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="vat" className="form-label  pt-1 start">VAT *</label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9
                      })}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0" id='vat' required
                      value={formData?.Vat}
                      isDisabled={val?.eye === true ? true : false}
                      onChange={(selectedOption) => handlevatChange(selectedOption)}
                      options={vat?.map((vat) => ({ value: vat, label: `${vat?.description}` }))}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom15" className="form-label  pt-1 start">Volume</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom15"
                      name="volume"
                      value={formData.volume}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom02" className="form-label  pt-1 start">Length</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      id="validationCustom02"
                      disabled={val?.eye === true ? true : false}
                      name="length"
                      value={formData.length}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom07" className="form-label  pt-1 start">Description *</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      id="description"
                      disabled={val?.eye === true ? true : false}
                      name="description" required
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom17" className="form-label  pt-1 start">Inventory UOM *</label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9
                      })}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0" required
                      isDisabled={val?.eye === true ? true : false}
                      value={formData.InventoryUom}
                      onChange={(selectedOption) => handleIUtMeasurementChange(selectedOption)}
                      options={unitMeasurement?.map((UM) => ({ value: UM, label: UM?.description }))}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom13" className="form-label  pt-1 start">Thickness</label>
                    <input
                      type="text"
                      className="form-Input w-70 noscroll"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom13"
                      name="thickness"
                      value={formData.thickness}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col   d-flex justify-content-between">
                    <label htmlFor="validationCustom08" className="form-label  pt-1 start">Height</label>
                    <input
                      type="text"
                      className="form-Input w-70 noscroll"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom08"
                      name="height"
                      value={formData?.height}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom17" className="form-label  pt-1 start">
                      Supplier
                    </label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9,
                      })}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0"
                      isDisabled={val?.eye === true ? true : false}
                      value={formData.Supplier}
                      onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                      options={supplier?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom17" className="form-label  pt-1 start">
                      Packing type *
                    </label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9
                      })}
                      isDisabled={val?.eye === true ? true : false}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0"
                      value={formData.PackingType}
                      onChange={(selectedOption) => handlePackingTypeChange(selectedOption)}
                      options={packingType?.map((packing) => ({ value: packing, label: packing?.description }))}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom13" className="form-label  pt-1 start">Reorder Level</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      id="validationCustom13"
                      disabled={val?.eye === true ? true : false}
                      name="reorderLevel"
                      value={formData.reorderLevel}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom08" className="form-label  pt-1 start">Size</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      id="validationCustom08"
                      disabled={val?.eye === true ? true : false}
                      name="size"
                      value={formData?.size}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom17" className="form-label  pt-1 start">
                      Category *
                    </label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9
                      })}
                      isDisabled={val?.eye === true ? true : false}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0"
                      value={formData?.Category}
                      onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                      options={categoryOptions?.map((CatOpt) => ({ value: CatOpt, label: CatOpt?.catName }))}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom17" className="form-label  pt-1 start">
                      packing UOM *
                    </label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9
                      })}
                      isDisabled={val?.eye === true ? true : false}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0"
                      value={formData.PackingUnitMeasurement}
                      onChange={(selectedOption) => handleUnitMeasurementChange(selectedOption)}
                      options={packingUom?.map((packing) => ({ value: packing, label: packing.description }))}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom13" className="form-label  pt-1 start">Max QTY</label>
                    <input
                      type="number" min={0}
                      onWheel={(e) => e.target.blur()}
                      className="form-Input w-70"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom13"
                      name="maxLevel"
                      value={formData.maxLevel}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom08" className="form-label  pt-1 start">Shape</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom08"
                      name="shape"
                      value={formData?.shape}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom17" className="form-label  pt-1 start">
                      Sub Category *
                    </label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9
                      })}
                      isDisabled={val?.eye === true ? true : false}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0"
                      value={formData?.SubCategory}
                      onChange={(selectedOption) => handleSubCategoryChange(selectedOption)}
                      options={subCategoryOptions?.map((SubCatOpt) => ({ value: SubCatOpt, label: SubCatOpt?.subcatname }))}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom13" className="form-label  pt-1 start">Sale Price</label>
                    <input
                      type="number" min={0}
                      onWheel={(e) => e.target.blur()}
                      className="form-Input w-70"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom13"
                      name="salePrice"
                      value={formData.salePrice}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom08" className="form-label  pt-1 start">Min QTY</label>
                    <input
                      type="number" min={0}
                      className="form-Input w-70"
                      disabled={val?.eye === true ? true : false}
                      onWheel={(e) => e.target.blur()}
                      id="validationCustom08"
                      name="minLevel"
                      value={formData?.minLevel}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <label htmlFor="validationCustom08" className="form-label  pt-1 start">Weight</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom08"
                      name="weight"
                      value={formData?.weight}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                  <div className="col-3 d-flex justify-content-between">
                    <label htmlFor="validationCustom17" className="form-label  pt-1 start">
                      Stock Type *
                    </label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        padding: 9
                      })}
                      isDisabled={val?.eye === true ? true : false}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                      className="w-70 p-0"
                      value={formData.StockType}
                      onChange={(selectedOption) => handleStockChange(selectedOption)}
                      options={stockType?.map((Stock) => ({ value: Stock, label: Stock?.stockTypeName }))}
                    />
                  </div>
                  <div className="col-3 d-flex justify-content-between">
                    <label htmlFor="validationCustom13" className="form-label  pt-1 start">Purchase Price</label>
                    <input
                      type="number" min={0}
                      className="form-Input w-70"
                      onWheel={(e) => e.target.blur()}
                      id="validationCustom13"
                      disabled
                      name="purchasePrice"
                      value={formData.purchasePrice}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-3  d-flex justify-content-between">
                    <label htmlFor="validationCustom08" className="form-label  pt-1 start">Width</label>
                    <input
                      type="text"
                      className="form-Input w-70"
                      disabled={val?.eye === true ? true : false}
                      id="validationCustom08"
                      name="width"
                      value={formData?.width}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-3">
                    {/* <label htmlFor="validationCustom08" className="form-label  pt-1 start ">(Conversion Rate *)</label> */}
                    <div className=" d-flex justify-content-between">
                      <label htmlFor="validationCustom08" className="form-label  pt-2 start m-0">
                        {formData?.PackingUnitMeasurement?.value?.description}{"   "}
                        {formData?.PackingUnitMeasurement?.value?.description ? '    =' : ''}
                      </label>
                      <input
                        type='number' min={0}
                        onWheel={(e) => e.target.blur()}
                        className="form-Input w-75"
                        id="convToInvUOM"
                        name="convToInvUOM"
                        placeholder='Conversion Rate'
                        disabled={val?.eye === true ? true : false}
                        value={formData?.convToInvUOM}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="validationCustom08" className="form-label  pt-2 end">{formData?.InventoryUom?.value?.description}</label>
                    </div>
                  </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                  <div className="col  d-flex justify-content-between ">
                    <div className='gap-3 mt-3'>
                      <input className="form-check-input"
                        type="checkbox"
                        id="Service" style={{ width: "20px", height: "20px" }}
                        disabled={val?.eye === true ? true : false}
                        name="Service"
                        checked={Service}
                        onChange={(e) => handleServiceSelection(e.target.checked)}
                      />
                      <label className='form-label px-2 pt-1'>Service</label>
                    </div>
                    <div className='gap-3 mt-3'>
                      <input className="form-check-input"
                        type="checkbox"
                        id="Assest" style={{ width: "20px", height: "20px" }}
                        disabled={val?.eye === true ? true : false}
                        name="Assests"
                        checked={Assests}
                        onChange={(e) => handleAssetsSelection(e.target.checked)}
                      />
                      <label className='form-label px-2 pt-1'>Fixed assets</label>
                    </div>
                  </div>
                  <div className="col  d-flex justify-content-between "></div>
                  <div className="col  d-flex justify-content-between ">
                    <div className='gap-3 mt-3'>
                      <input className="form-check-input"
                        type="checkbox"
                        id="Active" style={{ width: "20px", height: "20px" }}
                        name="Active"
                        disabled={val?.eye === true ? true : false}
                        checked={Active}
                        onChange={(e) => handleActiveSelection(e.target.checked)}
                      />
                      <label className='form-label px-2 pt-1'>Active</label>
                    </div>
                  </div>
                  <div className='col-2 start brderbox center p-0 m-0 '>
                    <a href=''
                      disabled={val?.eye === true ? true : false}
                    >Attachment</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cost-center-section">
            <div className="d-flex cursor-pointer slide-header gap-4"
              onClick={() => setglDetails(!glDetails)}>
              <div className='h6 pt-1'>GL Accounts</div>
            </div>
            <div className={`slide-content ${glDetails ? 'open' : 'closed'}`}>
              <div className="border-box2 p-3">
                <div className='row'>
                  <div className="col-lg-6">
                    <div className="form-group row  pb-3">
                      <div className="col-md-6 d-flex justify-content-between mb-3">
                        <label htmlFor="validationCustom03" className="form-label pt-1 start">Inventory Account</label>
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                          })}
                          isDisabled={val?.eye === true ? true : false}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                              color: state.isFocused ? '#000 ' : '#000',
                              ':hover': {
                                backgroundColor: '#F5F5F5',
                                color: '#000 ',
                              },
                            }),
                          }}
                          className="w-70 p-0"
                          value={formData.InventoryAccounts}
                          onChange={handleInventoryAccountChange}
                          options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                        />
                      </div>
                      <div className="col-md-6  d-flex justify-content-between mb-3">
                        <label htmlFor="validationCustom04" className="form-label  pt-1 start">CGS</label>
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                          })}
                          isDisabled={val?.eye === true ? true : false}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                              color: state.isFocused ? '#000 ' : '#000',
                              ':hover': {
                                backgroundColor: '#F5F5F5',
                                color: '#000 ',
                              },
                            }),
                          }}
                          className="w-70 p-0"
                          value={formData.cgsAccount}
                          onChange={handleCGSAccountChange}
                          options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                        />
                      </div>
                      <div className="col-md-6 d-flex justify-content-between">
                        <label htmlFor="validationCustom05" className="form-label  pt-1 start">Wastage Account</label>
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                          })}
                          isDisabled={val?.eye === true ? true : false}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                              color: state.isFocused ? '#000 ' : '#000',
                              ':hover': {
                                backgroundColor: '#F5F5F5',
                                color: '#000 ',
                              },
                            }),
                          }}
                          className="w-70 p-0"
                          value={formData.wastageAccount}
                          onChange={handleWastageAccountChange}
                          options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                        />
                      </div>
                      <div className="col-md-6 d-flex justify-content-between">
                        <label htmlFor="validationCustom12" className="form-label  pt-1 start">GRN Account</label>
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                          })}
                          isDisabled={val?.eye === true ? true : false}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                              color: state.isFocused ? '#000 ' : '#000',
                              ':hover': {
                                backgroundColor: '#F5F5F5',
                                color: '#000 ',
                              },
                            }),
                          }}
                          className="w-70 p-0"
                          value={formData.grnAccount}
                          onChange={handleGRNAccountChange}
                          options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group d-flex gap-3 pb-3">
                      <label htmlFor="validationCustom03" className="form-label  pt-1 start">Remarks</label>
                      <input
                        type="text"
                        className="form-Input w-50"
                        id="validationCustom03"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        disabled={val?.eye === true ? true : false}
                        required
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="w-50 d-flex justify-content-between gap-3">
                        <label htmlFor="validationCustom12" className="form-label  pt-1 start">Adjustment Account</label>
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                          })}
                          isDisabled={val?.eye === true ? true : false}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                              color: state.isFocused ? '#000 ' : '#000',
                              ':hover': {
                                backgroundColor: '#F5F5F5',
                                color: '#000 ',
                              },
                            }),
                          }}
                          className="w-70 p-0"
                          value={formData.adjustmentAccount}
                          onChange={handleAdjustmentAccountChange}
                          options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                        />
                      </div>
                      <div className='gap-3 pt-1'>
                        <input className="form-check-input"
                          type="checkbox"
                          id="DirectPurchase" style={{ width: "20px", height: "20px" }}
                          name="DirectPurchase"
                          disabled={val?.eye === true ? true : false}
                          checked={DirectPurchase}
                          onChange={(e) => handleDirectPurchaseSelection(e.target.checked)} />
                        <label className='px-2 pt-1'>Direct Purchase</label>
                      </div>
                      <div className='gap-3 pt-1' >
                        <input className="form-check-input"
                          type="checkbox"
                          id="FinishGood" style={{ width: "20px", height: "20px" }}
                          name="FinishGood"
                          checked={FinishGood}
                          disabled={val?.eye === true ? true : false}
                          onChange={(e) => handleFinishGoodSelection(e.target.checked)} />
                        <label className='px-2 pt-1'>Finish Good</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default CreateItem