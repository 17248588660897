import React, { useEffect, useState, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';

const PaymentTermGrid = ({ fetchPaymentData, rowData, canEditMaster, loading, setSelectedRow, handleEdit }) => {
    useEffect(() => {
        fetchPaymentData();
    }, []);
    const apiService = new ApiService();
    const [colDefs, setColDefs] = useState([
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: "Payment Term Code", field: "description_BI", filter: true, floatingfilter: true, minWidth: 500, editable: false },
        { headerName: "Payment Term Name", field: "description", filter: true, floatingfilter: true, minWidth: 500, editable: false },
        {
            headerName: 'Status', field: 'record_Id', cellRenderer: BtnCellRenderer, minWidth: 545,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                clicked: function (field, exp2) {
                    handleToggle(field, exp2);
                },
            },
        },
    ]);
    const updateActiveStatus = async (id, isActive) => {
        try {
            let body = {
                record_Id: id,
                exp2: isActive ? "1" : "0"
            }
            const res = await apiService.put(`UPDATE_EPAYMENT`, body);
            if (res?.status === 200) {
                toast.success('Updated successfully');
                fetchPaymentData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'description_BI') {
            setSelectedRow(null);
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const handleToggle = (recordId, newExp9Value) => {
        updateActiveStatus(recordId, newExp9Value === "1");
    };
    return (
        <div className='gridshad' style={{ height: 550 }}>
            <div className="ag-theme-quartz" style={{ height: 550 }}>
                <AgGridReact
                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    rowData={rowData}
                    rowSelection={'single'}
                    columnDefs={colDefs}
                    onCellClicked={onCellClicked}
                    onSelectionChanged={onSelectionChanged}
                />
            </div>
        </div>
    )
}
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const exp2Value = data.exp2 === "1" ? "0" : "1";
        clicked(value, exp2Value);
    }

    render() {
        const { canEditMaster } = this.props;
        const { exp2 } = this.props.data;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={exp2 === "1"}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={exp2 === "1"}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default PaymentTermGrid;
