import React from 'react';

export default (params) => (
    <span className="imgSpanLogo">
        {params.value && (
            <img
                alt={`${params.value} `}
                src={`http://posapi.qfinity.net/Resources/${params.value}`}
                style={{ width: "90px" }}
            />
        )}
    </span>
);