import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import Plus from "../../Assets/images/plusIcon.png"
import { ReactComponent as Add } from '../../Assets/svg/Add.svg';
import { RiSubtractLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { RxCross1 } from "react-icons/rx";
import ApiService2 from '../../../POS/services/apiService';
import { IoAddSharp } from "react-icons/io5";
const ItemGrid = ({ onRequisitionData, itemData, selectedCheck, handleVatChange, isDiscountPercentage }) => {
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        totalQty: '',
        baseQty: '',
        baseAmount: '',
        stock: '',
        amount: '',
        discount: '',
        discountAmount: '',
        calculatedAmount: "",
        vat: 0,
        vatDec: '',
        vatAmount: ''
    }]);
    const [itemOptions, setItemOptions] = useState([]);
    const [descriptionOptions, setDescriptionOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [vat, setvat] = useState([]);
    const [items, setItems] = useState([]);
    const apiService = new ApiService();
    const apiService2 = new ApiService2();
    const netAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVat = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    const totalDiscountAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.discountAmount) || 0), 0)
    const totalDiscount = rows.reduce((sum, row) => sum + (parseFloat(row?.discount) || 0), 0)
    const calculatedTotal = rows.reduce((sum, row) => sum + (parseFloat(row?.amount - row?.vatAmount) || 0), 0)

    useEffect(() => {
        getItems();
        getVat()
    }, [itemData, selectedCheck, isDiscountPercentage]);
    useEffect(() => {
        const getItems = async () => {
            const res = await apiService.get('GET_ITEM');
            debugger
            const formattedRows = rows?.map(detail => {
                const selectedItem = res.data.result?.find(item => item.itemDescription === detail.itemDescription);
                const selectedVat = vat?.find(tax => tax?.description === selectedItem?.vat);
                const amount = detail.qty * detail.baseAmount;
                const vatAmount = (amount / 100) * (selectedVat?.rate || 0);
                const excludedAmount = amount + vatAmount;
                const calculatedAmount = selectedCheck ? excludedAmount : amount;
                let discountamount = 0
                if (isDiscountPercentage) {
                    const discountAmount = ((detail.discount || 0) / 100) * calculatedAmount;
                    discountamount = discountAmount;
                } else {
                    discountamount = detail.discount || 0;
                }
                let finalAmount = 0;
                if (isDiscountPercentage) {
                    const discountAmount = ((detail.discount || 0) / 100) * calculatedAmount;
                    finalAmount = calculatedAmount - discountAmount;
                } else {
                    finalAmount = calculatedAmount - detail.discount || 0;
                }
                debugger
                return {
                    srno: detail?.srno,
                    ItemNo: detail.itemCode || detail.ItemNo,
                    recordId: detail.itemCode || detail.ItemNo,
                    itemDescription: detail.itemDescription,
                    qty: parseInt(detail.qty) || 0,
                    uom: detail.uom,
                    stock: detail.qty * detail.baseQty,
                    baseQty: detail.baseQty,
                    baseAmount: detail.baseAmount,
                    specification: detail.specification,
                    discount: detail.discount,
                    discountAmount: discountamount,
                    amount: finalAmount,
                    vat: selectedVat?.rate || 0,
                    vatDec: selectedVat?.description,
                    vatAmount: vatAmount || 0,
                    calculatedAmount: calculatedAmount,
                };
            }).filter(row => row !== null);
            if (formattedRows.length > 0) {
                setRows(formattedRows);
                onRequisitionData(formattedRows);
            }
        }

        if (!itemData) {
            getItems();
        }
    }, [itemData, selectedCheck, isDiscountPercentage]);
    const getVat = async () => {
        let param = `?isActive=true`
        const vat = await apiService.getApiParamater('GET_INV_TAX', param);
        setvat(vat.data.result)
    }
    const getItems = async () => {
        try {
            let param = `?isActive=true`
            const vatres = await apiService.getApiParamater('GET_INV_TAX', param);
            if (itemData?.poDetails) {
                const res = await apiService.get('GET_ITEM');
                const formattedRows = itemData?.poDetails.map(detail => {
                    const selecteditem = res.data.result?.find(item => item?.itemDescription === detail?.itemDescription);
                    const selectedVat = vatres.data.result?.find(tax => tax.description === selecteditem?.vat);
                    let amount = detail.qty * detail.unitPrice
                    const perAmount = (amount / 100) * selectedVat?.rate
                    let excludedAmount = amount + perAmount
                    const calculatedAmount = selectedCheck ? excludedAmount : amount;
                    let discountamount = 0
                    if (isDiscountPercentage) {
                        const discountAmount = (detail.discount / 100) * calculatedAmount;
                        discountamount = discountAmount;
                    } else {
                        discountamount = detail.discount;
                    }
                    let finalAmount = 0;
                    if (isDiscountPercentage) {
                        const discountAmount = (detail?.discount / 100) * calculatedAmount;
                        finalAmount = calculatedAmount - (discountAmount || 0);
                    } else {
                        finalAmount = calculatedAmount - (detail?.discount || 0);
                    }
                    return {
                        requisitionNo: itemData.requisitionNo || "",
                        srno: detail?.srno,
                        ItemNo: detail.itemCode,
                        recordId: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        qty: detail.qty,
                        uom: detail.uom,
                        stock: selecteditem.convToInvUOM * detail.qty,
                        baseAmount: detail.unitPrice,
                        baseQty: selecteditem.convToInvUOM,
                        specification: detail.specification,
                        amount: finalAmount,
                        vat: selectedVat?.rate || 0,
                        vatAmount: perAmount,
                        discountAmount: discountamount,
                        calculatedAmount: calculatedAmount,
                        discount: detail.discount
                    };
                }).filter(row => row !== null);

                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } else {
                if (!itemData?.requisitionNo) {
                    console.error('Requisition number not found in item data');
                    return;
                }
                const response = await apiService.get('GET_PR');
                const selectedPR = response?.data?.result?.find(req => req.requisitionNo === itemData.requisitionNo);
                if (!selectedPR) {
                    console.error('Selected PR not found');
                    return;
                }
                const prDetails = selectedPR?.prDetails || [];
                const res = await apiService.get('GET_ITEM');
                setItems(res.data.result)
                const formattedRows = itemData?.items.map(detail => {
                    const selectedItem = rows?.find(item => item?.itemDescription === detail?.itemDescription);
                    const selectedPritem = prDetails?.find(item => item.itemCode === detail.itemCode);
                    const selecteditem = res.data.result?.find(item => item?.itemDescription === detail?.itemDescription);
                    const selectedVat = vatres.data.result?.find(tax => tax?.description === selecteditem?.vat);
                    if (!selecteditem) {
                        console.error(`Selected item with description ${detail?.itemDescription} not found`);
                        return null;
                    }
                    let amount = detail.qty * detail.price
                    const perAmount = (amount / 100) * selectedVat?.rate
                    let excludedAmount = amount + perAmount
                    const calculatedAmount = selectedCheck ? excludedAmount : amount;
                    let discountamount = 0
                    if (isDiscountPercentage) {
                        const discountAmount = (detail.discount / 100) * calculatedAmount;
                        discountamount = discountAmount;
                    } else {
                        discountamount = detail.discount;
                    }
                    let finalAmount = 0;
                    if (isDiscountPercentage) {
                        const discountAmount = (selectedItem?.discount / 100) * calculatedAmount;
                        finalAmount = calculatedAmount - (discountAmount || 0);
                    } else {
                        finalAmount = calculatedAmount - (selectedItem?.discount || 0);
                    }
                    return {
                        requisitionNo: itemData.requisitionNo || "",
                        srno: itemData?.srno,
                        ItemNo: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        qty: detail.qty,
                        stock: selecteditem.convToInvUOM * detail.qty,
                        baseQty: selecteditem.convToInvUOM,
                        uom: detail.uom,
                        baseAmount: detail.price,
                        specification: selectedPritem ? selectedPritem.specification : '',
                        amount: finalAmount,
                        vat: selectedVat?.rate || 0,
                        vatDec: selectedVat?.description,
                        vatAmount: perAmount,
                        discount: selectedItem?.discount,
                        calculatedAmount: calculatedAmount,
                        discountAmount: discountamount,
                    };
                }).filter(row => row !== null);

                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    useEffect(() => {
        fetchData();
        getVat()
    }, []);
    const fetchData = async () => {
        try {
            const response = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            const selecteditem = response.data.result?.filter(rec => rec.exp9 === '1')
            if (response?.data?.result?.length > 0) {
                setApiData(selecteditem);
                setItemOptions(selecteditem.map(item => ({
                    value: item.itemNo,
                    label: item.itemNo
                })));
                setDescriptionOptions(selecteditem.map(item => ({
                    value: item.itemDescription,
                    label: item.itemDescription
                })));
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleInputChange = (index, fieldName, value) => {
        const isDuplicate = rows.some((row, rowIndex) => rowIndex !== index && (row.ItemNo === value || row.itemDescription === value));
        if (isDuplicate) {
            toast.error('Please choose a different item.');
            return;
        }
        const updatedRows = rows?.map((row, rowIndex) => {
            if (rowIndex === index) {
                const updatedRow = { ...row, [fieldName]: value, srno: ("0" + (index + 1)).slice(-2) };
                if (fieldName === 'ItemNo') {
                    const selectedItem = apiData?.find(item => item.itemNo === value);
                    const selectedVat = vat?.find(tax => tax.description === selectedItem?.vat);
                    if (selectedItem) {
                        updatedRow.itemDescription = selectedItem.itemDescription;
                        updatedRow.ItemNo = selectedItem.itemNo;
                        updatedRow.requisitionNo = selectedItem.itemDescription;
                        updatedRow.uom = selectedItem.baseUnit;
                        updatedRow.specification = selectedItem.specification;
                        updatedRow.vatDec = selectedVat?.description;
                        updatedRow.vat = selectedVat?.rate;
                        updatedRow.stock = 0;
                        updatedRow.baseAmount = selectedItem?.avgPrice || 0;
                        updatedRow.baseQty = parseFloat(selectedItem.convToInvUOM);
                    }
                } else if (fieldName === 'itemDescription') {
                    const selectedItem = apiData?.find(item => item.itemDescription === value);
                    const selectedVat = vat?.find(tax => tax.description === selectedItem?.vat);
                    if (selectedItem) {
                        updatedRow.specification = selectedItem.specification;
                        updatedRow.recordId = selectedItem.itemNo;
                        updatedRow.ItemNo = selectedItem.itemNo;
                        updatedRow.stock = 0;
                        updatedRow.uom = selectedItem.baseUnit;
                        updatedRow.baseQty = parseFloat(selectedItem.convToInvUOM);
                        updatedRow.vatDec = selectedVat?.description;
                        updatedRow.vat = selectedVat?.rate;
                        updatedRow.baseAmount = selectedItem?.avgPrice || 0;
                    }
                }
                if (fieldName === 'qty') {
                    const quantity = parseFloat(value);
                    const selectedItem = apiData?.find(data => data?.itemDescription === row?.itemDescription);
                    const baseQty = selectedItem ? selectedItem.convToInvUOM : 1;
                    updatedRow.stock = selectedItem?.convToInvUOM * quantity || 0;
                    updatedRow.baseQty = parseFloat(baseQty);
                }
                if (fieldName === 'discount') {
                    const discount = parseFloat(value);
                    const selectedItem = apiData?.find(data => data?.itemDescription === row?.itemDescription);
                    const quantity = parseFloat(updatedRow.qty);
                    const unitPrice = parseFloat(updatedRow.baseAmount || 0);
                    const selectedVat = vat?.find(tax => tax?.description === selectedItem?.vat);
                    debugger
                    const amount = quantity * unitPrice;
                    if (discount > amount) {
                        toast.error("Discount must be les then total Amount")
                        return
                    }
                    const vatAmount = (amount / 100) * (selectedVat?.rate || 0);
                    const excludedAmount = amount + vatAmount;
                    const calculatedAmount = selectedCheck ? excludedAmount : amount;
                    let discountamount = 0
                    if (isDiscountPercentage) {
                        const discountAmount = (discount / 100) * calculatedAmount;
                        discountamount = discountAmount;
                    } else {
                        discountamount = discount;
                    }
                    let finalAmount = 0;
                    if (!isNaN(discount) && discount >= 0) {
                        if (isDiscountPercentage) {
                            const discountAmount = (discount / 100) * calculatedAmount;
                            finalAmount = calculatedAmount - discountAmount;
                        } else {
                            finalAmount = calculatedAmount - discount;
                        }
                    } else {
                        finalAmount = calculatedAmount;
                    }
                    debugger
                    updatedRow.amount = finalAmount;
                    updatedRow.calculatedAmount = calculatedAmount;
                    updatedRow.stock = selectedItem?.convToInvUOM * quantity || 0;
                    updatedRow.vatDec = selectedVat?.description;
                    updatedRow.baseAmount = selectedItem?.avgPrice || 0;
                    updatedRow.discountAmount = discountamount;
                    updatedRow.discount = !isNaN(discount) && discount >= 0 ? discount : 0;
                }
                if (fieldName === 'qty' || fieldName === 'baseAmount') {
                    const selectedItem = apiData?.find(data => data?.itemDescription === row?.itemDescription);
                    const selectedVat = vat?.find(tax => tax?.description === selectedItem?.vat);
                    const quantity = parseFloat(updatedRow.qty);
                    const discount = updatedRow.discount;
                    const unitPrice = parseFloat(updatedRow.baseAmount || 0);
                    let amount = quantity * unitPrice
                    const perAmount = (amount / 100) * selectedVat?.rate
                    let excludedAmount = amount + perAmount
                    const calculatedAmount = selectedCheck ? excludedAmount : amount;
                    let discountamount = 0
                    if (isDiscountPercentage) {
                        const discountAmount = (discount / 100) * calculatedAmount;
                        discountamount = discountAmount;
                    } else {
                        discountamount = discount;
                    }
                    let finalAmount = 0;
                    if (!isNaN(discount) && discount >= 0) {
                        if (isDiscountPercentage) {
                            const discountAmount = (discount / 100) * calculatedAmount;
                            finalAmount = calculatedAmount - discountAmount;
                        } else {
                            finalAmount = calculatedAmount - discount;
                        }
                    } else {
                        finalAmount = calculatedAmount;
                    }
                    updatedRow.amount = finalAmount;
                    updatedRow.discount = discount;
                    updatedRow.stock = selectedItem?.convToInvUOM * quantity || 0;
                    updatedRow.discountAmount = discountamount;
                    updatedRow.calculatedAmount = calculatedAmount;
                    updatedRow.vatAmount = perAmount;
                    updatedRow.vatDec = selectedVat?.description;
                }
                return updatedRow;
            }
            return row;
        });
        setRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const addRow = () => {
        const newRow = {
            srno: '',
            ItemNo: '',
            requisitionNo: '',
            itemDescription: '',
            branch: '',
            qty: '',
            uom: '',
            baseQty: '',
            baseAmount: '',
            amount: '',
            stock: '',
            bol: ''
        };
        setRows([...rows, newRow]);
        onRequisitionData([...rows, newRow]);
    };
    const removeRow = () => {
        if (rows.length > 1) {
            const updatedRows = [...rows];
            updatedRows.pop();
            setRows(updatedRows);
            onRequisitionData(updatedRows);
        }
    };
    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            {itemData ?
                                <>{itemData?.eye === false ?
                                    <th></th> : " "
                                }</> :
                                <th></th>
                            }
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    {itemData ?
                        <tbody>
                            {itemData?.eye === false ?
                                <>{Array.isArray(rows) && rows?.map((row, index) => (
                                    <tr key={index}>
                                        {itemData?.pOrderNo === itemData?.requisitionNo ?
                                            <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                                <div className='center' onClick={() => handleRemoveRow(index)} > <RxCross1 size={15} /></div></td> :
                                            <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                                <div className='center'> <RxCross1 size={15} /></div></td>
                                        }
                                        <td className='m-0 p-0'><input className='p-2' type="text" value={row?.srno} disabled style={{ width: "80px" }} /></td>
                                        <td className='m-0 p-0'>
                                            {itemData?.pOrderNo === itemData?.requisitionNo ?
                                                <Select className="flex-grow-1"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        padding: 10
                                                    })}
                                                    options={itemOptions}
                                                    value={{ value: row.ItemNo, label: row.ItemNo }}
                                                    onChange={selectedOption => handleInputChange(index, 'ItemNo', selectedOption.value)} />
                                                :
                                                <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "100px" }} /></td>
                                            }
                                        </td>
                                        <td className='m-0 p-0'>
                                            {itemData?.pOrderNo === itemData?.requisitionNo ?
                                                <Select
                                                    className="flex-grow-1"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        padding: 10
                                                    })}
                                                    options={descriptionOptions}
                                                    value={{ value: row.itemDescription, label: row.itemDescription }}
                                                    onChange={selectedOption => handleInputChange(index, 'itemDescription', selectedOption.value)}
                                                />
                                                :
                                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "110px" }} /></td>
                                            }
                                        </td>
                                        <td className='m-0 p-0'><input className='p-2' type="text" disabled={itemData?.pOrderNo === itemData?.requisitionNo ? false : true}
                                            value={row.specification} onChange={e => handleInputChange(index, 'specification', e.target.value)}
                                            style={{ width: "120px" }} />
                                        </td>
                                        <td className='m-0 p-0'><input className='p-2' disabled={itemData?.pOrderNo === itemData?.requisitionNo ? false : true}
                                            onWheel={(e) => e.target.blur()} style={{ width: "90px" }}
                                            type="number" value={row.qty} onChange={e => handleInputChange(index, 'qty', e.target.value)} /></td>
                                        <td className='m-0 p-0'><input className='p-2' disabled={true} type="text" value={row.uom} style={{ width: "140px" }} /></td>
                                        <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.stock || 0} style={{ width: "120px" }} /></td>
                                        <td className='m-0 p-0'><input className='p-2' disabled={itemData?.pOrderNo === itemData?.requisitionNo ? false : true}
                                            onWheel={(e) => e.target.blur()} style={{ width: "100px" }}
                                            type="number" value={row.baseAmount} onChange={e => handleInputChange(index, 'baseAmount', e.target.value)} /></td>
                                        <td className='m-0 p-0'><input className='p-2' disabled type="number" value={row.discount} style={{ width: "100px" }} /></td>
                                        <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "120px" }}
                                            value={(row?.vat === undefined ? 0 : row?.vat + "%") || 0} /></td>
                                        <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                        <td className='m-0 p-0'><input className='p-2' disabled style={{ width: "120px" }}
                                            type="text" value={(row.amount === NaN ? 0 : row.amount) || 0} /></td>
                                    </tr>
                                ))}</>
                                : <>
                                    {rows?.map((row, index) => (
                                        <tr key={index}>
                                            <td className='m-0 p-0'><input className='p-2' type="text" value={row?.srno || index + 1} disabled style={{ width: "120px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "120px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "140px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "120px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.qty} style={{ width: "120px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "140px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.stock || 0} style={{ width: "140px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.baseAmount} style={{ width: "120px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.discount} style={{ width: "120px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' type="text" style={{ width: "120px" }} value={(row?.vat === undefined ? 0 : row?.vat + "%") || 0} disabled /></td>
                                            <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={(row.amount === NaN ? 0 : row.amount) || 0} style={{ width: "120px" }} /></td>
                                        </tr>
                                    ))}
                                </>
                            }
                        </tbody> :
                        <tbody>
                            {Array.isArray(rows) && rows.map((row, index) => (
                                <tr key={index}>
                                    {itemData?.requisitionNo ?
                                        <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                            <div className='center'> <RxCross1 size={15} /></div></td> :
                                        <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                            <div onClick={() => handleRemoveRow(index)} className='center'>
                                                <RxCross1 size={15} /></div></td>
                                    }
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.srno} disabled style={{ width: "80px" }} /></td>
                                    <td className='m-0 p-0'>
                                        <Select
                                            options={itemOptions}
                                            value={{ value: row.ItemNo, label: row.ItemNo }}
                                            className="flex-grow-1"
                                            theme={(theme) => ({
                                                ...theme,
                                                padding: 10
                                            })}
                                            onChange={selectedOption => handleInputChange(index, 'ItemNo', selectedOption.value)} />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <Select
                                            options={descriptionOptions}
                                            className="flex-grow-1"
                                            theme={(theme) => ({
                                                ...theme,
                                                padding: 10
                                            })}
                                            value={{ value: row.itemDescription, label: row.itemDescription }}
                                            onChange={selectedOption => handleInputChange(index, 'itemDescription', selectedOption.value)}
                                        />
                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.specification} style={{ width: "120px" }} onChange={e => handleInputChange(index, 'specification', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()}
                                        value={row.qty} style={{ width: "90px" }} onChange={e => handleInputChange(index, 'qty', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "140px" }} value={row.uom} onChange={e => handleInputChange(index, 'uom', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.stock || 0} style={{ width: "140px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()}
                                        value={row.baseAmount} style={{ width: "120px" }} onChange={e => handleInputChange(index, 'baseAmount', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()} disabled={itemData?.eye === true ? true : false}
                                        value={row.discount} style={{ width: "100px" }} onChange={e => handleInputChange(index, 'discount', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "120px" }} value={(row?.vat === undefined ? 0 : row?.vat + "%") || 0} onChange={e => handleInputChange(index, 'bol', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "120px" }} disabled type="text" value={(row.amount === NaN ? 0 : row.amount || 0) || 0} onChange={e => handleInputChange(index, 'amount', e.target.value)} /></td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
                {itemData ?
                    <>{itemData?.eye === false ?
                        <> {itemData?.pOrderNo === itemData?.requisitionNo ?
                            <div className='d-flex gap-5'>
                                <button className='mainbtn  mt-4 gap-3' onClick={addRow}><div>Add</div> <IoAddSharp color='#fff' className='center mt-1 mx-2' /> </button>
                            </div> : ""
                        }</>
                        : " "}
                    </> :
                    <div className='d-flex gap-5'>
                        <button className='mainbtn  mt-4 gap-3' onClick={addRow}><div>Add</div> <IoAddSharp color='#fff' className='center mt-1 mx-2' /> </button>
                    </div>
                }
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>{`${totalDiscount} ${(isDiscountPercentage === true ? "%" : " ")}` || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{calculatedTotal || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>{totalDiscountAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVat || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{netAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default ItemGrid;
