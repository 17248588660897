import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';

const ReceivedItem = ({ onRequisitionData, itemData, selectedIssue }) => {
    const apiService = new ApiService();
    const todayDate = new Date().toISOString().substr(0, 10);
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        reqQty: '',
        uom: '',
        issueQty: '',
        qty: '',
        balQty: '',
        amount: '',
        invQty: '',
        convRate: '',
        stock: '',
        unitPrice: '',
        cgsAcc: '',
        cgsAccName: '',
        check: false,
        date: todayDate
    }]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [itms, setItems] = useState([])
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVatAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    useEffect(() => {
        getItems();
    }, [selectedIssue]);
    useEffect(() => {
        onRequisitionData(rows);
    }, [rows]);
    const getItems = async () => {
        try {
            // let param = `?RequisitionNo=${formData.requisitionNo.label}`
            // const response = await apiService.getApiParamater('GET_REQ_ITEM_ISSUE', param);
            const itmsresponse = await apiService.get('GET_ITEM');
            setItems(itmsresponse?.data?.result)

            if (itemData) {
                // const grnItemMap = new Map(GrnItemData.map(detail => [detail.itemCode, detail]));

                const formattedRows = itemData?.purchaseReturnDetails?.map(detail => {
                    // const grnDetail = grnItemMap.get(detail.itemCode);
                    // const selectedPO = response?.data?.result?.find(req => req.pOrderNo === grnDetail?.pOrderNo);
                    let amount = detail.balQty * detail.unitPrice
                    const perAmount = (amount / 100) * detail?.vatPerc
                    let ExcludedAmount = amount + perAmount
                    // if (grnDetail) {
                    //     return {
                    //         ItemNo: detail.itemCode,
                    //         itemDescription: detail.itemDescription,
                    //         qty: detail.qty,
                    //         uom: detail.uom,
                    //         specification: detail.specification,
                    //         balqty: grnDetail.balQty || detail.qty,
                    //         stock: detail.convToInvUOM,
                    //         baseAmount: grnDetail.baseAmount,
                    //         unitPrice: grnDetail.unitPrice,
                    //         amount: selectedPO.vatIncluded === "Included" ? amount : ExcludedAmount,
                    //         vat: detail.vatPerc,
                    //         vatAmount: perAmount,
                    //     };
                    // }
                    return null;
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } else {
                debugger
                const formattedRowsPromises = selectedIssue?.issueDetails.map(async (detail, index) => {
                    debugger
                    const selecteditmstock = await apiService.getApiParamater('GET_ITEM', `?isActive=true&Warehouse=${selectedIssue?.warehouse}&ItemDescription=${detail?.itemDescription}`);
                    const selectedItem = itmsresponse?.data?.result?.find(req => req.itemDescription === detail.itemDescription);
                    let item = selecteditmstock?.data?.result?.[0]
                    return {
                        srno: index + 1,
                        ItemNo: detail.itemCode || selectedItem?.itemNo,
                        itemDescription: detail.itemDescription,
                        specification: detail.specification,
                        reqQty: detail.reqQty,
                        uom: detail.uom,
                        issueQty: detail.issueQty || 0,
                        balQty: detail.qty,
                        amount: detail.amount || 0,
                        // invQty: selectedItem.convToInvUOM * detail.qty,
                        invQty: 1 * detail.qty,
                        convRate: selectedItem.convToInvUOM,
                        stock: item?.stock || 0,
                        unitPrice: detail.unitPrice || 0,
                        check: detail.cgsAcc,
                        qty: detail.qty,
                        cgsAccName: detail.cgsAccName,
                        date:todayDate,
                    };
                }).filter(row => row !== null);
                const formattedRows = await Promise.all(formattedRowsPromises);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            }

        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    const handleCheckboxChange = (index, checked) => {
        const updatedRows = [...rows];
        updatedRows[index].check = checked;
        setRows(updatedRows);
        if (checked) {
            setSelectedRows([...selectedRows, updatedRows[index]]);
        } else {
            const filteredRows = selectedRows.filter((_, i) => i !== index);
            setSelectedRows(filteredRows);
        }
    };
    const handleDateChange = (index, date) => {
        const updatedRows = [...rows];
        updatedRows[index].date = date;
        setRows(updatedRows);
        const updatedSelectedRows = selectedRows.map((row, i) =>
            i === index ? { ...row, date: date } : row
        );
        setSelectedRows(updatedSelectedRows);
    };
    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Inventory UOM</th>
                            <th>Inventory Qty</th>
                            <th>Stock</th>
                            <th>BalForPo</th>
                            <th>Received</th>
                            <th>Received Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.srno} disabled style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "130px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.qty} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.uom} disabled style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.invQty} disabled style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.stock || 0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.balforPO || 0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'>
                                    <div className='center'>
                                        <input
                                            className='px-2 form-check-input'
                                            type="checkbox"
                                            style={{ width: "26.81px", height: "26.81px" }}
                                            checked={row.check}
                                            onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                        />
                                    </div>
                                </td>
                                <td className='m-0 p-0'>
                                    <input
                                        className='p-2'
                                        type='date'
                                        value={row?.date || todayDate}
                                        onChange={(e) => handleDateChange(index, e.target.value)}
                                        style={{ width: "140px" }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVatAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div> */}
        </div>
    );
};

export default ReceivedItem;

