import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import { TfiEmail } from "react-icons/tfi";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { FaFileCircleExclamation } from "react-icons/fa6";
import { RiRefreshLine } from "react-icons/ri";
import Pagination from '../pagination/pagination';
const ItemReports = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [StockType, setStockType] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [pagination, setPagination] = useState();
    const [Location, setLocation] = useState([]);
    const [Items, setItems] = useState([]);
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const onOpenModal = () => setEmailModal(true);
    const onCloseModal = () => setEmailModal(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        itemNo: { label: 'Select ItemNo', value: 'All' },
        ItemDescription: { label: 'Select Item', value: 'All' },
        email: "",
        Category: [{ label: 'Select Category', value: 'Select Category' }],
        SubCategory: [{ label: 'Select Sub Category', value: 'Select Sub Category' }],
        StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
        Warehouse: [{ label: 'Select Ware House', value: 'Select Sub Category' }],
    });
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const Items = await apiService.getApiParamater("GET_ITEM", param);
                setItems(Items?.data?.result);
                const categoryResponse = await apiService.getApiParamater("GET_CATEGORY", param);
                setCategoryOptions(categoryResponse?.data?.result);
                const subCategoryResponse = await apiService.getApiParamater("GET_SUB_CATEGORY", param);
                setSubCategoryOptions(subCategoryResponse?.data?.result);
                const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
                setStockType(stockType?.data?.result);
                let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
                setLocation(res?.data?.result)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const currentDatetimeUTC = new Date().toISOString();
            const params = new URLSearchParams();
            if (formData.itemNo.value.itemNo) {
                params.append('ItemNo', formData.itemNo.value.itemNo || '');
            }
            if (formData.itemNo.value.itemDescription) {
                params.append('ItemDescription', formData.itemNo.value.itemDescription || '');
            }
            params.append('Category', formData.Category?.value?.catCode || "0");
            params.append('SubCategory', formData.SubCategory?.value?.subcatcode || "0");
            params.append('StockType', formData.StockType?.value?.stockTypeCode || "0");
            params.append('Warehouse', formData.Warehouse?.label || "0");
            params.append('AsOnDate', currentDatetimeUTC);
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);

            const res = await apiService.getApiParamater('GET_ITEMS_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, Category: selectedOption });
    }
    const handleSubCategoryChange = (selectedOption) => {
        setFormData({ ...formData, SubCategory: selectedOption });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleStockChange = (selectedOption) => {
        setFormData({ ...formData, StockType: selectedOption });
    }
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, Warehouse: selectedOption });
    }
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'itemsReport.csv'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = ['itemNo', 'itemDescription', 'category', 'subCategory', 'stockType', 'unitMeasurement', 'warehouseLocation', 'purchasePrice'];

        Comparison.forEach(col => {
            columnHeaders.push(col.headerName);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                row.push(node.data[field]);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Item Master Report", 75, 12);
        doc.text("__________________________", 60, 15);
        doc.setLineWidth(2);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 6,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 6,
            },
            columnStyles: {
                0: { cellWidth: 19 },
                1: { cellWidth: 19 },
                2: { cellWidth: 19 },
                3: { cellWidth: 19 },
                4: { cellWidth: 19 },
                5: { cellWidth: 19 },
                6: { cellWidth: 19 },
                7: { cellWidth: 19 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 15 }
            },
            startY: 20,
            theme: 'striped'
        });
        doc.save('itemsReport.pdf');
    }, []);
    const handleClearFilters = () => {
        setFormData({
            itemNo: { label: 'Select ItemNo', value: 'All' },
            ItemDescription: { label: 'Select Item', value: 'All' },
            email: "",
            Category: [{ label: 'Select Category', value: 'Select Category' }],
            SubCategory: [{ label: 'Select Sub Category', value: 'Select Sub Category' }],
            StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
            Warehouse: [{ label: 'Select Ware House', value: 'Select Sub Category' }],
        });
        getItems()
    }
    const Comparison = [
        { headerName: 'Item Code', field: 'itemNo', filter: true, floatingfilter: true, suppressMovable: true, width: 120, },
        { headerName: 'Description ', field: 'itemDescription', filter: true, floatingfilter: true, suppressMovable: true, width: 500 },
        { headerName: 'Category', field: 'category', filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: 'Sub Category', field: 'subCategory', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'Stock Type', field: 'stockType', filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: 'Packing UOM', field: 'baseUnit', filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: 'Inventory UOM', field: 'unitMeasurement', filter: true, floatingfilter: true, suppressMovable: true, width: 125 },
        {
            headerName: 'Packing QTY', field: 'qty', filter: true, floatingfilter: true, suppressMovable: true, width: 130,
            valueFormatter: (params) => params?.value?.toFixed(2)
        },
        { headerName: 'Inventory Qty', field: 'inventoryQty', filter: true, floatingfilter: true, suppressMovable: true, width: 140 },
        { headerName: 'Warehouse', field: 'warehouseLocation', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        {
            headerName: 'Unit Price', field: 'price', filter: true, floatingfilter: true, suppressMovable: true, width: 150,
            valueFormatter: (params) => params?.value?.toFixed(2)
        },
        {
            headerName: 'Amount', field: 'amount', filter: true, floatingfilter: true, suppressMovable: true, width: 110,
            valueFormatter: (params) => params?.value?.toFixed(2)
        },
    ]
    const SendEmail = async () => {
        try {
            if (!formData.email) {
                toast.warning("Enter User Email to send Report")
                return;
            }
            const requestBody = {
                // toEmail: "shahzaibawan864@gmail.com",
                toEmail: formData.email,
                subject: "Item Report",
                body: `
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style='background-color: #5B93FF; color: #fff;'>
                            <tr>
                                <th>Item Code</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Stock Type</th>
                                <th>Packing UOM</th>
                                <th>Inventory UOM</th>
                                <th>Packing QTY</th>
                                <th>Inventory Qty</th>
                                <th>Warehouse</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${rowData?.map((row, index) => `
                                <tr key=${index}>
                                    <td className='m-0 p-0'><input style='width: 100px;' className='p-2' type='text' value='${row.itemNo}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.itemDescription}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.category}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.subCategory}' /></td>
                                    <td className='m-0 p-0'><input style='width: 80px;' className='p-2' disabled value='${row.stockType}' /></td>
                                    <td className='m-0 p-0'><input style='width: 150px;' className='p-2' disabled value='${row.baseUnit}' /></td>
                                    <td className='m-0 p-0'><input style='width: 120px;' className='p-2' disabled value='${row.unitMeasurement}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.qty}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.inventoryQty}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.warehouseLocation}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.price}' disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.amount?.toFixed(2) || 0}' disabled /></td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>`
            }
            const apiService = new ApiService();
            const response = await apiService.post("SEND_EMAIL", requestBody);
            if (response.status === 200) {
                onCloseModal()
                toast.success("Email send successfully")
                handleClearFilters()
            }
            debugger
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleItemChange = (selectedOption) => {
        setFormData({
            ...formData,
            itemNo: selectedOption ? { label: selectedOption.value.itemNo, value: selectedOption.value } : null,
            ItemDescription: selectedOption ? { label: selectedOption.value.itemDescription, value: selectedOption.value } : null,
        });
    }

    return (
        <div className='p-4'>
            <div className='gridBg m-0 row' style={{ borderRadius: "16px 16px 16px 16px" }}>
                <div className='col-8'>
                    <div className='row pb-2' >
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.itemNo}
                                onChange={(selectedOption) => handleItemChange(selectedOption)}
                                options={Items?.map((itm) => ({ value: itm, label: itm?.itemNo }))}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.ItemDescription}
                                onChange={(selectedOption) => handleItemChange(selectedOption)}
                                options={Items?.map((itm) => ({ value: itm, label: itm?.itemDescription }))}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData?.Category}
                                onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                                options={categoryOptions?.map((CatOpt) => ({ value: CatOpt, label: CatOpt?.catName }))}
                            />
                        </div>
                    </div>
                    <div className='row'  >
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData?.SubCategory}
                                onChange={(selectedOption) => handleSubCategoryChange(selectedOption)}
                                options={subCategoryOptions?.map((SubCatOpt) => ({ value: SubCatOpt, label: SubCatOpt?.subcatname }))}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.StockType}
                                onChange={(selectedOption) => handleStockChange(selectedOption)}
                                options={StockType?.map((Stock) => ({ value: Stock, label: Stock?.stockTypeName }))}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData?.Warehouse}
                                onChange={(selectedOption) => handlelocChange(selectedOption)}
                                options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                            />
                        </div>
                    </div>
                </div>
                <div className='col'></div>
                <div className='col-3 '>
                    <div onClick={() => getItems()} className='mainbtnGry flex-end gap-2'>
                        <FaFileCircleExclamation color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Search</div>
                    </div>
                    <div onClick={() => handleClearFilters()} className='mainbtnRed flex-end mt-2 gap-2'>
                        <RiRefreshLine color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Reset</div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between p-2 gridBg mt-2' style={{ borderRadius: "8px 8px 0px 0px " }}>
                <div className='mainInvHead center' >Item Master Report</div>
                <div className='d-flex gap-3 end'>
                    <div className='mainbtnGry flex-end gap-2'>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                <PiDownloadSimple size={25} />
                            </DropdownToggle>
                            <DropdownMenu className='mt-3'>
                                <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='mainbtnRed flex-end '><HiOutlinePrinter size={25} /></div>
                    <div className='mainbtn flex-end gap-1' onClick={onOpenModal}>
                        <div className='center'><TfiEmail size={25} /></div>
                        <Modal size="md"
                            show={emailModal} onHide={onCloseModal}
                            contentLabel='Email Modal'
                            style={customStyles}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className='center'>Send Email</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <input
                                        type='email'
                                        className="form-Input w-100"
                                        id="validationCustom02"
                                        value={formData.email}
                                        name="email"
                                        onChange={handleInputChange}
                                        placeholder='Enter Email'
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='end p-3'>
                                <button className='mainbtnGry' onClick={onCloseModal}>Close</button>
                                <button className='mainbtnRed' onClick={SendEmail}>Save</button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
            <div className='gridshad'>
                <div className="ag-theme-quartz" style={{ height: 550 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        ref={gridRef}
                        suppressExcelExport={true}
                    />
                </div>
                <Pagination pageSize={pageSize} pagination={pagination} getItems={getItems} setPageSize={setPageSize} />
            </div>
        </div>
    );
};
export default ItemReports;
const customStyles = {
    content: {
        width: '30%',
        height: '75%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
};