import React, { useState, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import MeasurementGrid from './grid';
import { toast } from 'react-toastify';
import ApiService from '../../../INVENTORY/services/apiService';
import { hasPermission } from "../../services/roleService"
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";

const UnitMeasurement = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [role, setRole] = useState();
    const [permissions, setPermissions] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [formData, setFormData] = useState({
        recordId: "",
        type: "",
        description: "",
    });
    useEffect(() => {
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('UOM Master', 'edit');
    // const canCreateItemMaster = checkPermission('UOM Master', 'create');

    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    const handleEdit = (clickedRowData) => {
        if (selectedRow || clickedRowData) {
            setFormData({
                recordId: clickedRowData?.recordId || selectedRow?.recordId,
                description: clickedRowData?.description || selectedRow?.description || "",
                type: clickedRowData?.type || selectedRow?.type || "",
            });
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        if (!formData.type || !formData.description) {
            if (!formData.description) { toast.warning("Enter Unit Of Measurement Description") }
            if (!formData.type) { toast.warning("Enter Unit Of Measurement type") }
            return;
        }
        try {
            const apiService = new ApiService();
            let data = {
                type: formData?.type,
                description: formData?.description,
            };
            const response = await apiService.post('CREATE_EUNIT_MEASUREMENT', data);
            if (response?.data?.statusCode === 201) {
                toast.success(response?.message);
                toast.success("Unit Measurement created Successfully");
                setFormData({
                    type: "",
                    description: "",
                });
                setRefreshKey(prevKey => prevKey + 1);
            } else {
                toast.error("Unit Measurement Already Exists")
            }
        } catch (error) {
            toast.error("Please try Again!");
        }
    };
    const fetchMeasurementData = useCallback(() => {
        setRefreshKey(prevKey => prevKey + 1);
    }, []);
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                description: formData?.description,
                type: formData?.type,
            }
            const res = await apiService.put(`UPDATE_EUNIT_MEASUREMENT`, body);
            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                setFormData({
                    recordId: "",
                    description: " ",
                    type: "",
                })
                window.history.replaceState({}, '')
                setSelectedRow(null)
                fetchMeasurementData()
            } else {
                console.error('Failed to update form data');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            description: " ",
            type: "",
        })
        setSelectedRow(null)
        fetchMeasurementData()
    };

    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Unit Of Measurement</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className=''>
                        {selectedRow ? (
                            <>
                                {canEditItemMaster && (
                                    <button className='mainbtn ' onClick={updateFormData}>
                                        <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                )}
                            </>
                        ) : (
                            <>
                                {canCreateItemMaster === true ?
                                    <button className='mainbtn ' onClick={handleSubmit}>
                                        <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                    <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                        <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                    </button>
                                }
                            </>
                        )}
                    </div>
                    <div className=''>
                        <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    </div>
                </div>
            </div>
            <div className='py-2'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className="form-group row ">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label start mt-2">Code *</label>
                            <input
                                type="text"
                                disabled={canCreateItemMaster === true ? false : true}
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="type"
                                value={formData.type}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label start mt-2">UOM *</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                disabled={canCreateItemMaster === true ? false : true}
                                id="validationCustom01"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                <MeasurementGrid refreshKey={refreshKey} fetchMeasurementData={fetchMeasurementData} canEditItemMaster={canEditItemMaster}
                    setSelectedRow={setSelectedRow} handleEdit={handleEdit} />
            </div>
        </div>
    )
}

export default UnitMeasurement;
